import { useEffect, useState } from "react"

import { Switch } from "@mui/material"

import { request } from "@/Api"
import { store } from "@/sheet/bidStore"

export default function BidTemplatePublishingSwitch(){
    const template = store.bid.bid_template
    const [bidTemplate, setBidTemplate] = useState({})
    
    useEffect(()=>{
        setBidTemplate(template)
    }, [])
    
    const updateBidTemplate = () => {
        const updatedBidTemplate = {...bidTemplate, public: !bidTemplate.public}
        request.put(`/bid-templates/${template.id}`, updatedBidTemplate)
        .then(resposne=>{
            setBidTemplate(resposne.data)
        })
    }

    if (!template){
        return null
    }

    return (
        <div>Public <Switch checked={bidTemplate.public || false} onChange={updateBidTemplate}></Switch></div>
    )
}
