import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { request } from "@/Api";
import { store } from "@/sheet/bidStore";

const NoteGridItem = (props) => {
    const { placeholder, field, onInputChange, onInputBlur, onInputFocus, localBid, readOnly } = props    
    return (
        <Grid item md={12}>
            <TextField
                value={localBid[field] || ""}
                placeholder={placeholder}
                fullWidth
                multiline
                minRows={4}
                onBlur={onInputBlur(field)}
                onInput={onInputChange(field)}
                onFocus={onInputFocus(field)}
                disabled={readOnly}
            />
        </Grid>
    )
}

export default function BidNotes(props){
    const { bidId, readOnly } = props
    const [localBid, setLocalBid] = useState({})
    const bid = store.bid

    const updateBid = (bidPayload) => {
        request.patch(`/bids/${bidId}`, bidPayload)
    }

    const onInputChange = (field) => (event) => {
        // TODO : implement working line handling
        setLocalBid({...localBid, [field]: event.target.value})
    }

    const onInputBlur = (field) => (event) => {
        var value = event.target.value.trim()
        setLocalBid({...localBid, [field]: value})

        if (bid[field] != value) {
            updateBid({...localBid, [field]: value})
        }
    }

    const onInputFocus = (field) => (event) => {
        event.preventDefault()
    }

    useEffect(()=>{
        if (!bid.id) {
            return
        }
        setLocalBid({...bid, top_sheet_sender_ids: bid.top_sheet_senders.map((sender)=>{return sender.id})})
    },[bid])

    return (
        <>
            <Grid item><p>Notes</p></Grid>

            <Grid container spacing={4}>
                <NoteGridItem
                    placeholder="Project Assumptions"
                    field="assumptions"
                    onInputChange={onInputChange}
                    onInputBlur={onInputBlur}
                    onInputFocus={onInputFocus}
                    localBid={localBid}
                    readOnly={readOnly}
                />
                <NoteGridItem
                    placeholder="Bid Includes..."
                    field="inclusions"
                    onInputChange={onInputChange}
                    onInputBlur={onInputBlur}
                    onInputFocus={onInputFocus}
                    localBid={localBid}
                    readOnly={readOnly}
                />
                <NoteGridItem
                    placeholder="Bid does not include..."
                    field="exclusions"
                    onInputChange={onInputChange}
                    onInputBlur={onInputBlur}
                    onInputFocus={onInputFocus}
                    localBid={localBid}
                    readOnly={readOnly}
                />
            </Grid>
        </>
    )
}
