import { useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from 'styled-components'

import { useTheme } from "@mui/material"
import TextField from "@mui/material/TextField"
import Modal from "@mui/material/Modal"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"

import ErrorIcon from '@mui/icons-material/Error';

import { rateTypes, resourceTypes, INTERNAL_MARKUP } from "@/bid/constants"
import { CustomBox, TargetsWarning, Title } from "@/common/StyledComponents"
import { RadioButton } from "@/common/CommonComponents"
import { request } from "@/Api"
import { selectWorkingGroup, cancelWorkingGroup, addCategory, addSection, updateGroup, updateLine, selectLinesByGroupId, selectGroupsByGroupId } from "@/bid/bidSlice"
import CircularProgress from '@mui/material/CircularProgress';

const Subtitle = styled.h3`
    margin: 2px 0 24px 0;
    font-size: 14px;
    font-weight: 600;
`

const SmallRed = styled.span`
    color: red;
    font-size: 12px;
`

export default function LineGroupPopup(props){   
    const { sendSocketMessage } = props
    const dispatch = useDispatch()
    const theme = useTheme()
    const [ lineGroupLocal, setLineGroupLocal ] = useState({}) 
    const [ warningOpen, setWarningOpen] = useState(false)
    const [ groupUpdating, setGroupUpdating ] = useState(false)
    const lineGroup = useSelector(selectWorkingGroup)

    const groups = useSelector(state => selectGroupsByGroupId(state, lineGroup.id))
    const lines = useSelector(state => selectLinesByGroupId(state, lineGroup.id))

    const isOverriding = lineGroup.popupType == "override"
    const isCategory = lineGroup.parent_id ? true : false

    const markupPropogated = useMemo(() => {
        const markup = lines.length == 0 ? lineGroup.markup : lines[0].markup
        const mixed = lines.some(line => line.markup != markup)
        return mixed ? null : markup
    }, [lineGroup])

    const rateTypePropogated = useMemo(() => {
        const rateType = groups.length > 0 ? groups[0].rate_type : lineGroup.rate_type
        return groups.some(group => group.rate_type != rateType) ? null : rateType
    }, [lineGroup])

    const resourceTypePropogated = useMemo(() => {
        const resourceType = groups.length > 0 ? groups[0].is_makers_fees : lineGroup.is_makers_fees
        return groups.some(group => group.is_makers_fees != resourceType) ? null : resourceType
    }, [lineGroup])
    
    useEffect(()=>{
        if (Object.keys(lineGroup).length > 0){
            setLineGroupLocal({
                ...lineGroup,
                markup: lineGroup.is_makers_fees ? INTERNAL_MARKUP : isOverriding ? markupPropogated : lineGroup.markup,
                rate_type: isCategory ? lineGroup.rate_type : (isOverriding ? rateTypePropogated : lineGroup.rate_type),
                is_makers_fees: isCategory ? lineGroup.is_makers_fees : (isOverriding ? resourceTypePropogated : lineGroup.is_makers_fees)
            })
        }
    }, [lineGroup])

    const handleInput = (field) => (event) => {
        const isMarkup = field == "markup"
        const value = event.target.value
        setLineGroupLocal({...lineGroupLocal, [field]: isMarkup ? value/100 : event.target.value})
    }

    const handleRateTypeChange = (event) => {
        setLineGroupLocal({...lineGroupLocal, rate_type: event.target.getAttribute("value")})
    }

    const handleResourceTypeChange = (event) => {
        const isInternal = event.target.getAttribute("value") == "true"
        setLineGroupLocal({
            ...lineGroupLocal,
            is_makers_fees: isInternal,
            markup: isInternal ? INTERNAL_MARKUP : lineGroupLocal.markup,
        })
    }

    const handleCancel = () => {
        dispatch(cancelWorkingGroup())
        setLineGroupLocal({})
    }

    const handleCreate = () => {
        request.post(`/bids-legacy/${lineGroupLocal.bid_id}/line-groups`, lineGroupLocal).then((response) => {
            dispatch(response.data.parent_id ? addCategory({...response.data, initialExpanded: true}) : addSection(response.data))
            sendSocketMessage({ type: "addGroup", groupId: response.data.id })
            setLineGroupLocal({})
            setWarningOpen(false)
        })
    }

    const updateLineGroup = () => {
        setGroupUpdating(true)
        request.put(`/bids-legacy/${lineGroupLocal.bid_id}/line-groups/${lineGroupLocal.id}`, lineGroupLocal)
        .then(response => {
            dispatch(updateGroup(response.data))
            sendSocketMessage({ type: "updateGroup", groupId: lineGroup.id })
            setLineGroupLocal({})
            setWarningOpen(false)
            setGroupUpdating(false)
        })
    }

    const popupTypeMapping = {
        create: {
            title: `Create a new ${lineGroup.lineGroupType}`,
            buttonText: "Add",
            buttonCallback: handleCreate,
        },
        override: {
            title: `Override all under ${lineGroup.lineGroupType} (use with caution!)`,
            buttonText: "Override all",
            buttonCallback: ()=>{setWarningOpen(true)},
            markupFieldWarning: markupPropogated != null ? null : <SmallRed>(Mixed settings!)</SmallRed>,
            rateTypeFieldWarning: (isCategory || !isCategory && groups.length == 0) ? null : (rateTypePropogated != null ? null : <SmallRed>(Mixed settings!)</SmallRed>),
            resourceTypeFieldWarning: (isCategory || !isCategory && groups.length == 0) ? null : (resourceTypePropogated != null ? null : <SmallRed>(Mixed settings!)</SmallRed>),            
        }
    }

    return (
        <Modal 
            onKeyDown={(e) => {e.key!="Enter" || popupTypeMapping[lineGroup.popupType].buttonCallback()}}
            open={lineGroup.open}
            onClose={handleCancel}>
            <CustomBox
                sx={{ background: theme.palette.background.default }}
            >
                <div style={{marginBottom: 30}}>
                    <Title>{lineGroup.popupType ? popupTypeMapping[lineGroup.popupType].title : ""}</Title>
                    <TextField
                        value={lineGroupLocal.name || ""}
                        onChange={handleInput("name")}
                        size="small"
                        placeholder={`Name ${lineGroupLocal.lineGroupType}`}
                        fullWidth
                    />
                </div>

                <div style={{marginBottom: 20}}>
                    <p>Rate Type {lineGroup.popupType ? popupTypeMapping[lineGroup.popupType].rateTypeFieldWarning : ""}</p>
                    <Grid container spacing={3}>
                        {rateTypes.map(rateTypeOption=>
                            <Grid key={rateTypeOption} md={3} item>
                                <RadioButton
                                    theme={theme}
                                    value={rateTypeOption}
                                    label={rateTypeOption}
                                    checked={lineGroupLocal.rate_type == rateTypeOption}
                                    onClick={handleRateTypeChange}
                                />
                            </Grid>
                        )}
                    </Grid>
                </div>

                <div style={{marginBottom: 40}}>
                    <Grid container spacing={3}>
                        <Grid md={6} item height={"max-content"}>
                            <p>Markup {lineGroup.popupType ? popupTypeMapping[lineGroup.popupType].markupFieldWarning : ""}</p>
                            {lineGroupLocal.is_makers_fees ? (
                                <TextField 
                                    value="n/a"
                                    size="small" 
                                    fullWidth
                                    sx={{textAlign: "right"}}
                                    InputProps={{
                                        startAdornment: <span style={{fontSize: 16, color: "grey"}}>Markup</span>
                                    }}
                                    inputProps={{style: {textAlign: "right"}}}
                                    disabled
                                />
                            ) : (
                                <TextField 
                                    type="number"
                                    value={(lineGroupLocal.markup*100).toFixed() == 0 ? "" : (lineGroupLocal.markup*100).toFixed()}
                                    onChange={handleInput("markup")}
                                    onWheel={ event => event.target.blur() }
                                    size="small" 
                                    fullWidth
                                    sx={{textAlign: "right"}}
                                    InputProps={{
                                        endAdornment: <span style={{fontSize: 16, color: "grey", marginLeft:8}}>%</span>,
                                        startAdornment: <span style={{fontSize: 16, color: "grey"}}>Markup</span>
                                    }}
                                    inputProps={{style: {textAlign: "right"}}}
                                />
                            )}
                            
                        </Grid>
                        <Grid md={6} item height={"max-content"}>
                            <p>Resource Type {lineGroup.popupType ? popupTypeMapping[lineGroup.popupType].resourceTypeFieldWarning : ""}</p>
                            <Grid container spacing={3}>
                                {resourceTypes.map(resourceTypeOption=>
                                    <Grid key={resourceTypeOption} md={6} item>
                                        <RadioButton 
                                            theme={theme}
                                            value={resourceTypeOption == "Internal"}
                                            label={resourceTypeOption}
                                            checked={lineGroupLocal.is_makers_fees == (resourceTypeOption == "Internal")}
                                            onClick={handleResourceTypeChange}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Grid container justifyContent={"right"} spacing={3}>
                    <Grid item md={3}>
                        <Button fullWidth variant="outlined" onClick={handleCancel}>Cancel</Button>
                    </Grid>
                    <Grid item md={3}>
                        <Button fullWidth variant="contained" 
                            sx={{color: "black"}} 
                            onClick={lineGroup.popupType ? popupTypeMapping[lineGroup.popupType].buttonCallback : null}
                        >
                            {lineGroup.popupType ? popupTypeMapping[lineGroup.popupType].buttonText : ""}
                        </Button>
                    </Grid>
                </Grid>

                <Modal open={warningOpen} onClose={()=>{setWarningOpen(false)}}>
                    <CustomBox sx={{ background: theme.palette.background.default }}>
                        <TargetsWarning>
                            <Grid container spacing={1}>
                                <Grid item><ErrorIcon color="warning"/></Grid>
                                <Grid item>
                                    <Subtitle>Attention</Subtitle>
                                    <p>These changes will be applied to all existing line items in this {lineGroupLocal.lineGroupType}. <br/> Please ensure that your modifications are intended to overwrite existing values before proceeding</p>
                                </Grid>
                                <Grid container justifyContent={"right"} spacing={3}>
                                    <Grid item md={3}>
                                        <Button fullWidth variant="outlined" onClick={()=>{setWarningOpen(false)}}>Cancel</Button>
                                    </Grid>
                                    <Grid item md={3}>
                                        {groupUpdating ? <CircularProgress /> : <Button fullWidth variant="contained" onClick={updateLineGroup}>Yes! Override</Button>}
                                    </Grid>
                                </Grid>                 
                            </Grid>
                        </TargetsWarning>
                    </CustomBox>
                </Modal>
            </CustomBox>
        </Modal>
    )
}
