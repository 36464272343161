import { uuidv4 } from "@/utils"
import { ADD_BUTTON_CELL_HEIGHT, ADDON_ROW_ID_PREFIX, BID_STATUS_PENDING_EP, BID_STATUS_PENDING_FINANCE, LINE_GROUP_ROW_ID_PREFIX, LINE_ITEM_ROW_ID_PREFIX, ROW_CELL_HEIGHT } from "./constants"
import { getRow , headerRow } from "./sheetUtils"
import { AddButtonCell } from "./AddButtonCell"
import { getServiceItemsByCategory } from "./serviceItemsStore"
import { currencies } from "@/constants"
import { BID_STATUS_DRAFT, bidCategories, bidSections } from "@/sheet/constants"
import { ProfileListItem } from "@/profiles_common/CommonComponents"

import SortRoundedIcon from '@mui/icons-material/SortRounded';
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import AddAlarmRoundedIcon from '@mui/icons-material/AddAlarmRounded';
import MovingRoundedIcon from '@mui/icons-material/MovingRounded';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';

export const store = {
    bid: {}, 
    localData: {
        expantions: {},
        openedDropdownCell: {
            rowId: null,
            columnId: null
        },
    }
}

const boldedColumns = [
    "name", "client_budget", "client_quoted"
]

export const columnProfiles = [
    {id: "", type: "text", width: 10, defaultWidth: 10, renderer: () => <span style={{cursor: "pointer"}}>≡</span>, alwaysVisible: true, nonEditable: true},
    {id: "name", headerIcon: <SortRoundedIcon fontSize="tiny" style={{paddingLeft: 32}}/>, headerText: "Name", type: "customChevron", defaultWidth: 300, width: 300, alwaysVisible: true},
    // {id: "id", type: "text", alwaysVisible: true},
    // {id: "sort_order", type: "number", alwaysVisible: true},
    {id: "is_makers_fees", headerIcon: <LocalAtmRoundedIcon fontSize="tiny"/>, headerText: "Resource Type", type: "customDropdownResourceType", visible: true},
    {id: "vendor_currency", headerIcon: <LocalAtmRoundedIcon fontSize="tiny"/>, headerText: "Currency", type: "customDropdownCurrency", visible: true},
    {id: "vendor_rate", headerIcon: <LocalOfferOutlinedIcon fontSize="tiny"/>, headerText: "Rate", type: "simpleFormula", visible: true},
    {id: "rate_type", headerIcon: <AccessTimeRoundedIcon fontSize="tiny"/>, headerText: "Rate Type", type: "customDropdownRateType", visible: true},    
    {id: "units", headerIcon: <ShoppingCartOutlinedIcon fontSize="tiny"/>, headerText: "Qty", type: "simpleFormula", visible: true},
    {id: "days", headerIcon: <PeopleOutlineRoundedIcon fontSize="tiny"/>, headerText: "Units", type: "simpleFormulaOneAsEmpty", visible: true},
    {id: "ot_1_5", headerIcon: <AddAlarmRoundedIcon fontSize="tiny"/>, headerText: "OT 1.5", type: "simpleFormula", visible: true},
    {id: "ot_2_0", headerIcon: <AddAlarmRoundedIcon fontSize="tiny"/>, headerText: "OT 2.0", type: "simpleFormula", visible: true},
    {id: "markup", headerIcon: <MovingRoundedIcon fontSize="tiny"/>, headerText: "Markup", type: "number"},
    {id: "adjustment", headerIcon: <BuildOutlinedIcon fontSize="tiny"/>, headerText: "Adjustments", type: "simpleFormula"},
    {id: "internal_notes", headerIcon: <NoteAltOutlinedIcon fontSize="tiny"/>, headerText: "Notes", type: "text", defaultWidth: 300, width: 300},
    //{id: "client_quoted_margin", headerIcon: <LocalAtmRoundedIcon fontSize="tiny"/>, headerText: "Margin", type: "number", nonEditable: true, visible: true, format: Intl.NumberFormat("en-US", {style: "percent",})},
    //{id: "client_gross_profit", headerIcon: <LocalAtmRoundedIcon fontSize="tiny"/>, headerText: "GP", type: "number", nonEditable: true, visible: true},
    {id: "client_budget", headerIcon: <PaymentsOutlinedIcon fontSize="tiny"/>, headerText: "Budget", type: "number", nonEditable: true, sticky: true, visible: true},
    {id: "client_quoted", headerIcon: <PaidOutlinedIcon fontSize="tiny"/>, headerText: "Quoted", type: "number", nonEditable: true, sticky: true, alwaysVisible: true},
    // {id: "parent_id", type: "number"},
    // {id: "line_group_id", type: "number"},
    // {id: "depth", type: "number"},
    // {id: "internal_notes", type: "text"},
    // {id: "external_notes", type: "text", alwaysVisible: true},
]

const fieldsSelectedValueMapping = {
    is_makers_fees: {
        true: "Internal",
        false: "External"
    }
}

export const traverseLineItems = (lineItemsGrouped) => {
    let lineItems = []
    let stack = lineItemsGrouped
    while (stack.length > 0) {
        const currentGroup = stack.pop()
        lineItems = [...lineItems, ...currentGroup.line_items]
        stack = [...stack, ...currentGroup.children]
    }
    return lineItems
}

export const traverseLineGroups = (lineItemsGrouped) => {
    let lineGroups = [...lineItemsGrouped]
    let stack = lineItemsGrouped
    while (stack.length > 0) {
        const currentGroup = stack.pop()
        lineGroups = [...lineGroups, ...currentGroup.children]
        stack = [...stack, ...currentGroup.children]
    }
    return lineGroups
}

export const traverseAddons = (lineItemsGrouped) => {
    let addons = []
    let stack = lineItemsGrouped
    while (stack.length > 0) {
        const currentGroup = stack.pop()
        addons = [...addons, ...currentGroup.addons]
        stack = [...stack, ...currentGroup.children]
    }
    return addons
}

const getAddButtonRow = ( type, parentGroup, parentGroupRowId, serviceItems, depth ) => {
    const processedColumnProfiles = getProcessedColumnProfiles()
    const addButtonHasChildren = false
    const addButtonColumnProfile = processedColumnProfiles.map((profile, index)=>profile.id=="name" ? {
        id: "name",
        type: "text", 
        nonEditable: true,
        alwaysVisible: true,
        colspan: processedColumnProfiles.length - 1,
        renderer: () => 
        <div style={{paddingLeft: depth * 20 + 32, width: "100%"}}>
            <AddButtonCell 
                type={type}
                parent={parentGroup || store.bid}
                serviceItemOptions={serviceItems}
                onAddGroup={(groupId)=>{
                    store.localData.expantions[groupId] = true
                }}
            />
        </div>
    } : {id: "", type: "text", text: ""})
    return getRow(
        {id: uuidv4(), depth: depth}, 
        addButtonColumnProfile, 
        addButtonHasChildren, 
        parentGroupRowId,
        depth,
        ADD_BUTTON_CELL_HEIGHT
    )
}

export const getProcessedColumnProfiles = () => {
    const colProfiles = []
    const stickyColProfiles = []
    columnProfiles.forEach(col=>{
        const collection = col.sticky ? stickyColProfiles : colProfiles
        if (col.visible || col.alwaysVisible) {
            collection.push(col)
        }
        if (col.id == "markup") {
            col.format = getPercentageFormatter()
        } else if (col.id == "client_budget" || col.id == "client_quoted") {
            col.format = getClientCurrencyFormatter()
        }
    })

    return [...colProfiles, ...stickyColProfiles]
}

// consider optimization: try to aggregate reactgrid from maintaining (instead of fully override) original data
// code clean up: break down this function to smaller functions
export const bidToRows = () => {
    const processedColumnProfiles = getProcessedColumnProfiles()
    let rows = []
    let stack = [...store.bid.line_items_grouped, {addButton: "section", depth: 0, parent_id: null}].reverse()
    while (stack.length > 0) {
        const currentGroup = stack.pop()
        const currentGroupHasChildren = true
        const groupRowId = `${LINE_GROUP_ROW_ID_PREFIX}-${currentGroup.id}`
        const groupParentRowId = currentGroup.parent_id && `${LINE_GROUP_ROW_ID_PREFIX}-${currentGroup.parent_id}`
        const lineItemHasChildren = false
        const addonHasChildren = false
        const currentGroupDepth = currentGroup.depth || 0
        const nextGroupDepth = currentGroupDepth + 1

        if (currentGroup.addButton) {
            const parentGroup = store.bid.lineGroups.find(group=>group.id==currentGroup.parent_id)
            if (parentGroup) {
                parentGroup.addons.forEach(addon=>{
                    const addonParentId = addon.line_group_id && `${LINE_GROUP_ROW_ID_PREFIX}-${addon.line_group_id}`
                    const addonRowId = `${ADDON_ROW_ID_PREFIX}-${addon.id}`
                    rows.push(getRow(
                        {...addon, depth: nextGroupDepth, id: addonRowId}, 
                        processedColumnProfiles, 
                        addonHasChildren, 
                        addonParentId, 
                        currentGroupDepth,
                        ROW_CELL_HEIGHT,
                        null,
                    ))
                })
            }
    
            rows.push(getAddButtonRow(
                currentGroup.addButton,
                parentGroup,
                groupParentRowId,
                currentGroup.addButton == "section" ? bidSections : bidCategories,
                currentGroupDepth
            ))
            continue
        }

        rows.push(getRow(
            {...currentGroup, id: groupRowId}, 
            processedColumnProfiles, 
            currentGroupHasChildren, 
            groupParentRowId, 
            currentGroup.depth,
            ROW_CELL_HEIGHT,
            store.localData.expantions[groupRowId],
            store.localData.openedDropdownCell,
            false,
            fieldsSelectedValueMapping
        ))

        currentGroup.line_items.forEach(line=>{
            const lineItemParentId = line.line_group_id && `${LINE_GROUP_ROW_ID_PREFIX}-${line.line_group_id}`
            const lineItemRowId = `${LINE_ITEM_ROW_ID_PREFIX}-${line.id}`
            rows.push(getRow(
                {...line, depth: nextGroupDepth, id: lineItemRowId}, 
                processedColumnProfiles, 
                lineItemHasChildren, 
                lineItemParentId, 
                nextGroupDepth,
                ROW_CELL_HEIGHT,
                null,
                store.localData.openedDropdownCell,
                false,
                fieldsSelectedValueMapping
            ))
        })

        if (currentGroup.line_items.length > 0 || currentGroup.depth >= 1) {
            currentGroup.addons.forEach(addon=>{
                const addonParentId = addon.line_group_id && `${LINE_GROUP_ROW_ID_PREFIX}-${addon.line_group_id}`
                const addonRowId = `${ADDON_ROW_ID_PREFIX}-${addon.id}`
                rows.push(getRow(
                    {...addon, depth: nextGroupDepth, id: addonRowId}, 
                    processedColumnProfiles, 
                    addonHasChildren, 
                    addonParentId, 
                    nextGroupDepth,
                    ROW_CELL_HEIGHT,
                    null,
                ))
            })
            const serviceItemNames = getServiceItemsByCategory(currentGroup.name).map(item=>item.name)
            rows.push(getAddButtonRow("line", currentGroup, groupRowId, serviceItemNames, nextGroupDepth))
        }

        let childrenWithDepthInfo = [...currentGroup.children.map(group=>{return {...group, depth: nextGroupDepth}})]
        if (!currentGroup.parent_id) {
            childrenWithDepthInfo = [...childrenWithDepthInfo, {addButton: "category", depth: nextGroupDepth, parent_id: currentGroup.id}].reverse()
        }
        stack = stack.concat(childrenWithDepthInfo)
    }
    const header = headerRow(processedColumnProfiles)
    const allRows = [header, ...rows]

    return allRows
}

export const storeBid = (bid) => {
    store.bid = bid
    store.bid.lineItems = traverseLineItems([...bid.line_items_grouped])
    store.bid.lineGroups = traverseLineGroups([...bid.line_items_grouped])
    // store.bid.addons = traverseAddons([...bid.line_items_grouped])

    store.bid.project_rd_bid_ids = []

    storeInsights(bid, bid.status == BID_STATUS_DRAFT || bid.status == BID_STATUS_PENDING_EP || bid.status == BID_STATUS_PENDING_FINANCE)
    
    store.bid.top_sheet_sender_ids = bid.top_sheet_senders.map((sender)=>{return sender.id})
}

export const getLineItemsHash = () => {
    if (!store.bid.lineItems) {
        return {}
    }
    return Object.fromEntries(store.bid.lineItems.map(line => [line.id, line]))
}

export const getLineGroupsHash = () => {
    if (!store.bid.lineGroups) {
        return {}
    }
    return Object.fromEntries(store.bid.lineGroups.map(group => [group.id, group]))
}

export const getQuotedThreshold = (external_budget, external_percentage, internal_budget, internal_percentage) => {
    return (external_budget / (1 - external_percentage)) + (internal_budget / (1 - internal_percentage))
}

export const storeInsights = (insights, includeBidinProject) => {
    const {
        external_quoted,
        internal_quoted,
        addons_quoted,
        total_quoted,
        external_budget,
        internal_budget,
        gross_profit,
        overhead,
        net_profit,
        project,
    } = insights

    store.bid.insights = {
        external_quoted,
        internal_quoted,
        addons_quoted,
        total_quoted,
        external_budget,
        internal_budget,
        gross_profit,
        overhead,
        net_profit,

        project_external_quoted: project.external_quoted,
        project_internal_quoted: project.internal_quoted,
        project_addons_quoted: project.addons_quoted,
        project_total_quoted: project.total_quoted,
        project_external_budget: project.external_budget,
        project_internal_budget: project.internal_budget,
        project_gross_profit: project.gross_profit,
        project_overhead: project.overhead,
        project_net_profit: project.net_profit,
    }

    if (includeBidinProject) {
        store.bid.insights.project_external_quoted += external_quoted
        store.bid.insights.project_internal_quoted += internal_quoted
        store.bid.insights.project_addons_quoted += addons_quoted
        store.bid.insights.project_total_quoted += total_quoted
        store.bid.insights.project_external_budget += external_budget
        store.bid.insights.project_internal_budget += internal_budget
        store.bid.insights.project_gross_profit += gross_profit
        store.bid.insights.project_overhead += overhead
        store.bid.insights.project_net_profit += net_profit
    }

    store.bid.insights.target_quoted = getQuotedThreshold(
        store.bid.insights.project_external_budget, 0.15, 
        store.bid.insights.project_internal_budget, 0.5
    )
    store.bid.insights.minimum_quoted = getQuotedThreshold(
        store.bid.insights.project_external_budget, 0.1, 
        store.bid.insights.project_internal_budget, 0.5
    )
}

// TEMP for legacy bid integration
export const storeBills = (bills) => {
    store.bid.bills = bills
}

// Setters

export const addLineItem = (id, lineItem) => {
    bid.lineItems[id] = lineItem
    bid.lineGroups[lineItem.line_group_id].push(lineItem) 
}

export const addLineGroup = (id, lineGroup) => {
    bid.lineGroup[id] = lineGroup
    if (lineGroup.parent_id) {
        bid.lineGroup[lineGroup.parent_id].children.push(lineGroup)
    }
}

export const updateLineItem = (id, lineItem) => {
    const line = bid.lineItems[id]
    Object.entries(lineItem).map((k, v) => {
        line[k] = v
    })
}

export const updateLineGroup = (id, lineGroup) => {
    const group = bid.lineGroups[id]
    Object.entries(lineGroup).map((k, v) => {
        lineGroup[k] = v
    })
}

export const getOfficeCurrency = () => {
    return store.bid.project?.legal_entity.country.currency || "CAD"
}

export const getClientCurrency = () => {
    return store.bid.project?.currency || "CAD"
}

export const getClientCurrencyFormatter = () => {
    const currencyFormatter = new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: store.bid.project?.currency || "CAD",
        maximumFractionDigits: 0,
    })
    return currencyFormatter     
}

export const getPercentageFormatter = () => {
    const percentageFormatter = new Intl.NumberFormat("en-CA", {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    })
    return percentageFormatter   
}

export const getReadOnly = () => {
    return store.bid.status != BID_STATUS_DRAFT
}

export const getRootAddons = () => {
    if (!store.bid.addons) {
        return []
    }
    return store.bid.addons.filter(addon=>!addon.line_group_id)
}

export const fitColumnWidthsToContainer = (container) => {
    const columnsToFit = columnProfiles.filter(profile=>(!profile.defaultWidth&&(profile.visible||profile.alwaysVisible)))
    const allVisibleFixedColumn = columnProfiles.filter(profile=>(profile.defaultWidth&&(profile.visible||profile.alwaysVisible)))
    const totalFixedWidth = allVisibleFixedColumn.reduce((acc, col) => acc + col.width,0)
    const newWidth = (container.clientWidth - totalFixedWidth) / columnsToFit.length
    const width = Math.max(newWidth, 120)
    columnsToFit.forEach(profile=>{
        profile.width = width
    })
}

export const getStyledRows = (rowsData, themeMode) => {
    const rows = [...rowsData]
    const styles = themeMode == "light" ? [
        {
            section: {color: "#225982", background: "#94D1FF"},
            category: {color: "#225982", background: "#C8E7FE"},
            line: {color: "#2C8FDA", background: "#EEF5FF"},
        },
        {
            section: {color: "#724707", background: "#FFDAA2"},
            category: {color: "#955D09", background: "#FCEACE"},
            line: {color: "#CB7F0E", background: "#FAF7F2"},
        },
        {
            section: {color: "#055832", background: "#95E7C1"},
            category: {color: "#055832", background: "#BBEFD7"},
            line: {color: "#148451", background: "#E8FCF3"},
        },
    ] : [
        {
            section: {color: "#D5EDFF", background: "#0082E4"},
            category: {color: "#D5EDFF", background: "#015A9D"},
            line: {color: "#D5EDFF", background: "#002E50"},
        },
        {
            section: {color: "#FFECD0", background: "#D08D28"},
            category: {color: "#FFECD0", background: "#9F6A1C"},
            line: {color: "#FFECD0", background: "#533911"},
        },
        {
            section: {color: "#DAEEE5", background: "#21A468"},
            category: {color: "#DAEEE5", background: "#1A754C"},
            line: {color: "#DAEEE5", background: "#0C4129"},
        },
    ]

    // Set hiararchy colors

    rows.forEach(row=>{
        const stylesStack = [...styles]
        const isCategory = row.rowId.split("-")[0] == LINE_GROUP_ROW_ID_PREFIX && row.parentId != null
        const isSection = row.rowId.split("-")[0] == LINE_GROUP_ROW_ID_PREFIX && row.parentId == null
        const processedColumnProfiles = getProcessedColumnProfiles()

        for (var i = row.cells.length - 1; i > 0; i -- ) {
            const cell = row.cells[i]
            if (processedColumnProfiles[i].sticky) {
                const style = stylesStack.pop()
                const cellStyle = isSection ? style.section : isCategory ? style.category : style.line
                if (!cell.style) {
                    cell.style = {}
                }
                cell.style.color = cellStyle.color
                cell.style.background = cellStyle.background
            }
        }
    })

    // Set cell border color

    if (themeMode == "dark") {
        rows.forEach(row=>{
            row.cells.forEach(cell=>{
                cell.style = {...cell.style, border: {
                    top: {color: "#464646"},
                    bottom: {color: "#464646"},
                    left: {color: "#464646"},
                    right: {color: "#464646"},
                }}
                cell.style.boxShadow = "none"
            })
        })
    }

    // Set outer table boarder

    const firstRowCells = rows[0].cells
    const lastRowCells = rows[rows.length-1].cells
    const firstColumnCells = rows.map(row=>row.cells[0])
    const lastColumnCells = rows.map(row=>row.cells[row.cells.length-1])
    firstRowCells.forEach(cell=>{
        if (!cell.style) {
            cell.style = {}
        }
        cell.style = {...cell.style, border: {...cell.style.border, top: {color: "transparent"}}}
    })
    firstColumnCells.forEach(cell=>{
        cell.style = {...cell.style, border: {...cell.style.border, left: {color: "transparent"}}}
    })
    lastRowCells.forEach(cell=>{
        cell.style = {...cell.style, border: {...cell.style.border, bottom: {color: "transparent"}}}
    })
    lastColumnCells.forEach(cell=>{
        cell.style = {...cell.style, border: {...cell.style.border, right: {color: "transparent"}}}
    })

    // Set row styles

    rows.forEach(row=>{
        const isSection = row.rowId.split("-")[0] == LINE_GROUP_ROW_ID_PREFIX && row.parentId == null
        if (isSection) {
            row.cells.forEach(cell=>{
                if (boldedColumns.includes(cell.columnId)) {
                    cell.style.fontWeight = 800
                }
            })
        }
    })

    return rows

}

export function getLineItemRate(existingRate, lineItem, rateType) {
    if (!lineItem || !lineItem.vendor_rate) {
        return existingRate
    }

    switch(rateType) {
        case "Hours":
            return Math.round(lineItem.vendor_rate / HOURS_PER_DAY * 100) / 100
        case "Days":
        case "Percentage":
            return lineItem.vendor_rate
        case "Flat rate":
        default:
            return existingRate
    }
}
