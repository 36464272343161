import styled from 'styled-components'

import { useTheme } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import Grid from '@mui/material/Grid'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import { store } from '@/sheet/bidStore'
import { formattedCurrency } from "@/utils"


const Container = styled.div`
    background: ${props => props.theme.palette.background.paper};
    padding: 32px 46px;
    box-sizing: border-box;
    border-radius: 20px;
`
const Table = styled.table`
    border-collapse: collapse;
    font-size: 12px;

    th {
        text-align: left;
        padding: 12px 48px 12px 0;
        width: 100%;

        span {
            display: inline-block;
            vertical-align: bottom;
            margin-left: 6px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: ${props => props.theme.palette.text.disabled};
            color: ${props => props.theme.palette.text.secondary};
            font-size: 12px;
            text-align: center;
        }
    }
    td {
        text-align: right;
        padding: 12px 48px;
        white-space: nowrap;

        &.rightBorder {
            border-right: ${props => `1px solid ${props.theme.palette.text.disabled}`};
        }

        &.warning {
            color: ${props => props.theme.palette.warning.main};
            cursor: help;
        }
        &.error {
            color: ${props => props.theme.palette.error.main};
            cursor: help;
        }
    }

    thead {
        th {
            padding-top: 0;
        }
        td {
            font-size: 12px;
            color: ${props => props.theme.palette.text.secondary};
            padding-top: 0;
        }

    }

    tbody {
        th {
            font-weight: normal;
            border-bottom: ${props => `1px solid ${props.theme.palette.text.disabled}`};
        }
        td {
            border-bottom: ${props => `1px solid ${props.theme.palette.text.disabled}`};
        }
    }

    tfoot {
        td {
            font-weight: bold;
        }
    }
`
const TooltipTotalQuotedContainer = styled.div`
    color: ${props => props.theme.palette.text.primary};
    font-size: 12px;
    
    h3 {
        margin: 0 0 24px 0;
        font-weight: 500;
        font-size: 12px;
    }

    h4 {
        margin: 0 0 8px 0;
        font-weight: 400;
        font-size: 12px;
    }

    p {
        color: ${props => props.theme.palette.text.secondary};
        margin: 0;
    }

    span {
        color: ${props => props.theme.palette.success.main};

        &.warning {
            color: ${props => props.theme.palette.warning.main};
        }
        &.error {
            color: ${props => props.theme.palette.error.main};
        }
    }

    .box {
        background-color: ${props => props.theme.palette.background.paper};
        padding: 12px 16px;
        border-radius: 10px;
        max-width: 250px;
    }

`

const TooltipTh = ({ title, tooltip }) => (
    <th>
        {title}
        <Tooltip title={tooltip} placement='right'>
            <span><QuestionMarkIcon sx={{fontSize: "10px"}} /></span>
        </Tooltip>
    </th>
)

const TooltipTotalQuoted = ({ theme, quoted, target, minimum, currency }) => {
    const difference = quoted - target
    const label = difference < 0 ? "below" : "above"
    const severity = quoted < minimum ? "error" : quoted < target ? "warning" : ""

    return (
        <TooltipTotalQuotedContainer theme={theme}>
            <h3>
                Total Quoted is <span className={severity}>
                    {formattedCurrency(Math.abs(difference), currency, true)} {label} target
                </span>
            </h3>

            <Grid container spacing={2}>
                <Grid item>
                    <div className="box">
                        <h4>Target Quoted: {formattedCurrency(target, currency, true)}</h4>
                        <p>Target Quoted calculated as (External Budget / 85%) + (Internal Budget / 50%)</p>
                    </div>
                </Grid>
                <Grid item>
                    <div className="box">
                        <h4>Minimum Quoted: {formattedCurrency(minimum, currency, true)}</h4>
                        <p>Minimum Quoted calculated as (External Budget / 90%) + (Internal Budget / 50%)</p>
                    </div>
                </Grid>
            </Grid>

        </TooltipTotalQuotedContainer>
    )
}
export default function BidInsights({ currency }){
    const theme = useTheme()

    const insights = store.bid.insights
    const totalFlag = insights.project_total_quoted < insights.minimum_quoted ? "error" : insights.project_total_quoted < insights.target_quoted ? "warning" : ""

    return (
        <Grid container spacing={4}>
            <Grid item flexGrow={1}>
                <Container theme={theme}>
                    <Table theme={theme}>
                        <thead>
                            <tr>
                                <th>Quoted</th>
                                <td className="rightBorder">Current Bid</td>
                                <td>Project Total</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <TooltipTh title="External Quoted" tooltip='Sum of all lines Quoted w/ resource type "External"' />
                                <td className="rightBorder">{formattedCurrency(insights.external_quoted, currency, true)}</td>
                                <td>{formattedCurrency(insights.project_external_quoted, currency, true)}</td>
                            </tr>
                            <tr>
                                <TooltipTh title="Internal Quoted" tooltip='Sum of all lines Quoted w/ resource type "Internal"' />
                                <td className="rightBorder">{formattedCurrency(insights.internal_quoted, currency, true)}</td>
                                <td>
                                    {formattedCurrency(insights.project_internal_quoted, currency, true)}
                                </td>
                            </tr>
                            <tr>
                                <TooltipTh title="Add-ons Quoted" tooltip='Sum of all lines Quoted as "Add-ons" (including insurance)' />
                                <td className="rightBorder">{formattedCurrency(insights.addons_quoted, currency, true)}</td>
                                <td>{formattedCurrency(insights.project_addons_quoted, currency, true)}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <TooltipTh title="Total Quoted" tooltip="Sum of all the above" />
                                <td className="rightBorder">{formattedCurrency(insights.total_quoted, currency, true)}</td>
                                <Tooltip 
                                    title={<TooltipTotalQuoted
                                        theme={theme}
                                        quoted={insights.project_total_quoted}
                                        target={insights.target_quoted}
                                        minimum={insights.minimum_quoted}
                                        currency={currency}
                                    />}
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                backgroundColor: theme.palette.background.default,
                                                borderRadius: "20px",
                                                padding: "22px",
                                                boxShadow: "0px 0px 50px 0px #0000001A",
                                                maxWidth: "600px",
                                            }
                                        }
                                    }}
                                    placement="bottom"
                                >
                                    <td className={totalFlag}>
                                        {formattedCurrency(insights.project_total_quoted, currency, true)}
                                    </td>
                                </Tooltip>
                            </tr>
                        </tfoot>
                    </Table>
                </Container>
            </Grid>

            <Grid item flexGrow={1}>
                <Container theme={theme}>
                    <Table theme={theme}>
                        <thead>
                            <tr>
                                <th>Profitability</th>
                                <td className="rightBorder">Current Bid</td>
                                <td>Project Total</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <TooltipTh title="External Budget" tooltip='Sum of all lines Budget w/ resource type "External"' />
                                <td className="rightBorder">{formattedCurrency(insights.external_budget, currency, true)}</td>
                                <td>{formattedCurrency(insights.project_external_budget, currency, true)}</td>
                            </tr>
                            <tr>
                                <TooltipTh title="Gross Profit" tooltip="Total Quoted - External Budget" />
                                <td className="rightBorder">{formattedCurrency(insights.gross_profit, currency, true)}</td>
                                <td>{formattedCurrency(insights.project_gross_profit, currency, true)}</td>
                            </tr>
                            <tr>
                                <TooltipTh title="Labour & Overhead" tooltip="70% of internal quoted + 2% of External Quoted" />
                                <td className="rightBorder">{formattedCurrency(insights.overhead, currency, true)}</td>
                                <td>{formattedCurrency(insights.project_overhead, currency, true)}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <TooltipTh title="Net Profit" tooltip="Gross Profit - Labour & Overhead" />
                                <td className="rightBorder">{formattedCurrency(insights.net_profit, currency, true)}</td>
                                <td>{formattedCurrency(insights.project_net_profit, currency, true)}</td>   
                            </tr>
                        </tfoot>
                    </Table>
                </Container>
            </Grid>
        </Grid>
    )
}
