import { RATE_TYPE_PERCENTAGE, RATE_TYPE_FLAT_RATE } from "./constants"

export const topSheetColumns = [
    {
        field: "name", 
        name: "Item", 
        align: "left", 
        minWidth: 6, 
        isTotalField: true,
        ignoredByRateTypes: []
    },
    {
        field: "external_notes", 
        name: "Notes", 
        align: "left", 
        minWidth: 4.5,
        isTotalField: false,
        ignoredByRateTypes: []
    },
    {
        field: "vendor_rate", 
        name: "Vendor Rate", 
        align: "right", 
        minWidth: 4.5,
        isTotalField: false,
        ignoredByRateTypes: []
    },
    {
        field: "client_rate", 
        name: "Client Rate", 
        align: "right", 
        isTotalField: false,
        ignoredByRateTypes: []
    },
    {
        field: "is_makers_fees", 
        name: "Resource", 
        align: "left",
        minWidth: 3, 
        isTotalField: false,
        ignoredByRateTypes: []
    },
    {
        field: "rate_type", 
        name: "Rate Type", 
        align: "left",
        minWidth: 3.5,
        isTotalField: false,
        ignoredByRateTypes: []
    },
    {
        field: "units", 
        name: "Qty", 
        align: "right",
        minWidth: 3, 
        isTotalField: false,
        ignoredByRateTypes: []
    },
    {
        field: "days", 
        name: "Units",
        align: "right", 
        minWidth: 3, 
        isTotalField: false,
        ignoredByRateTypes: [RATE_TYPE_FLAT_RATE]
    },
    {
        field: "ot_1_5", 
        name: "OT @ 1.5", 
        align: "right", 
        minWidth: 3.5, 
        isTotalField: false,
        ignoredByRateTypes: [RATE_TYPE_FLAT_RATE, RATE_TYPE_PERCENTAGE]
    },
    {
        field: "ot_2_0", 
        name: "OT @ 2.0", 
        align: "right", 
        minWidth: 3.5, 
        isTotalField: false,
        ignoredByRateTypes: [RATE_TYPE_FLAT_RATE, RATE_TYPE_PERCENTAGE]
    },
    {
        field: "markup", 
        name: "Markup", 
        align: "right", 
        minWidth: 3.5, 
        isTotalField: false,
        ignoredByRateTypes: []
    },
    {
        field: "adjustment", 
        name: "Adjustment", 
        align: "right", 
        minWidth: 1, 
        isTotalField: false,
        ignoredByRateTypes: []
    },
    {
        field: "client_quoted",
        name: "Cost", 
        align: "right", 
        isTotalField: true,
        ignoredByRateTypes: []
    },
]