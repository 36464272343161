import * as React from "react";
import {
  isNavigationKey,
  getCellProperty,
  isAlphaNumericKey,
  keyCodes
} from "@silevis/reactgrid";
import "./customCellStyle.css"
import { Autocomplete, Chip, ClickAwayListener, Grid, ListItem, TextField } from "@mui/material";
import { useState } from "react";
import "./dropdownCellTemplateStyle.css"
import HighlightCell from "./HighlightCell";
import ConfirmationDialog from "@/common/ConfirmationDialog";
import { LINE_GROUP_ROW_ID_PREFIX } from "./constants";
import LineGroupWarningPopup from "./LineGroupWarningPopup";

export default class CustomDropdownCellTemplate {
    constructor (highlights, options) {
        this.highlights = highlights
        this.options = options
        this.variant = null
        if(options.length > 0 && typeof(options[0]) == "object") {
            this.variant = "chip"
        }
    }

    getClassName(cell) {
        return "dropdown-cell"
    }

    getStyle() {
        return {
            overflow: "visible"
        }
    }

    getCompatibleCell(uncertainCell) {
        const text = getCellProperty(uncertainCell, "text", "string");
        const value = parseFloat(text);
        return { ...uncertainCell, text, value, enableEditMode: true};
    }

    handleKeyDown(
        cell,
        keyCode,
        ctrl,
        shift,
        alt
    ){
        if (!ctrl && !alt && isAlphaNumericKey(keyCode) || keyCode == "onClick"){
            const char = String.fromCharCode(keyCode)
            const updatedCell = this.update(cell, { ...cell, text: char, value: parseFloat(char) })
            return { updatedCell, enableEditMode: true }
        }
        return {
            cell,
            enableEditMode: keyCode == keyCodes.POINTER || keyCode == keyCodes.ENTER
        }
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text, enableEditMode: true });
    }

    render(cell, isInEditMode, onCellChanged = (cell, commit) => {}) {
        const cellHighlights = this.highlights.filter(h=>(h.rowId == cell.rowId) && (h.columnId == cell.columnId))
        return (
            <CustomDropdownCell 
                cell={cell}
                highlights={cellHighlights}
                variant={this.variant}
                options={this.options}
                onCommitCellChange={(newCellValue)=>{
                    onCellChanged(
                        this.getCompatibleCell({ ...cell, text: newCellValue, value: parseFloat(newCellValue) }),
                        true
                    )
                }}
            />
        )
    }
}

const CustomDropdownCell = ({cell, variant, options, onCommitCellChange, highlights}) => {
    const [ hover, setHover ] = useState() 
    const isGroup = cell.rowId.split("-")[0] == LINE_GROUP_ROW_ID_PREFIX
    const handleMouseEnter = () => {
        setHover(true)
    }
    const handleMouseLeave = () => {
        setHover(false)
    }
    return (
        <div style={{width: "100%"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <CustomAutocomplete
                cell={cell}
                variant={variant}
                options={options}
                onCommitCellChange={onCommitCellChange}
                isGroup={isGroup}
            />
            {(highlights.length > 0) && <HighlightCell highlights={highlights} hover={hover}/>}
        </div>
    )
}

const CustomAutocomplete = ({cell, variant, options, onCommitCellChange, isGroup}) => {
    const [ editing, setEditing ] = useState(false)
    const [ selectedValue, setSelectedValue ] = useState(null)
    const [ valueToCommit, setValueToCommit ] = useState(null)
    const [ dialogOpen, setDialogOpen ] = useState(false)

    const commitChange = () => {
        const value = variant == "chip" ? valueToCommit.name : valueToCommit
        onCommitCellChange(value)
        setSelectedValue(valueToCommit)
    }

    const handleAutocompleteChange = (event, newValue) => {
        const value = variant == "chip" ? newValue.name : newValue
        setEditing(false)
        if (!isGroup) {
            setSelectedValue(value)
            onCommitCellChange(value)
        } else {
            setValueToCommit(newValue)
            setDialogOpen(true)
        }
    }

    const handleAutocompleteClear = (event, newValue, reason) => {
        if (reason == "clear"){
            onCommitCellChange(null)
            setSelectedValue(null)
        }
    } 

    const handleInputKeyDown = (e) => {
        e.stopPropagation()
    }

    return (
        // Using ClickAwayListener ensures the blur behaviour happens after autocomplete change
        <>
            {isGroup &&
            <LineGroupWarningPopup 
                isOpen={dialogOpen}
                onClose={()=>{setDialogOpen(false)}}
                onConfirm={commitChange}
            />}
            <Grid container alignItems={"center"} width={"100%"}>
                <Grid item width={"100%"}>
                    <ClickAwayListener onClickAway={()=>{setEditing(false)}}>
                        {editing ?
                        <Autocomplete
                            disableClearable
                            open
                            style={{padding: 0}}
                            value={selectedValue || cell.text}
                            sx={{width: "100%"}}
                            disablePortal
                            options={options}
                            getOptionLabel={(option)=>option.name || option}
                            renderOption={(props, option, { selected }) => {
                                return getOptionComponent(props, option, variant)
                            }}
                            renderInput={(params) =>
                                <TextField
                                    sx={{border: "none", outline: "none"}}
                                    autoFocus
                                    {...params}
                                    onPointerDown={(e)=>{e.stopPropagation()}}
                                    inputProps={{...params.inputProps, style: {fontSize: 12, border: "none", padding: 2, paddingLeft: 6}}}
                                    InputProps={{...params.InputProps, style: {padding: 0, fontSize: 12}}}
                                />
                            }
                            onKeyDown={handleInputKeyDown}
                            onChange={handleAutocompleteChange}
                            onInputChange={handleAutocompleteClear}
                            onPointerDown={(e)=>{e.stopPropagation()}}
                            isOptionEqualToValue={(option, value)=>{
                                return variant=="chip" ? option.name == value : option == value
                            }}
                        /> :
                        <div style={{cursor: "pointer"}} onClick={()=>{setEditing(true)}}>
                            {getOptionComponent(
                                null,
                                variant=="chip"?options.find(option=>option.name==cell.text):cell.text,
                                variant,
                                editing
                            )}
                        </div>
                        }
                    </ClickAwayListener>
                </Grid>
            </Grid>
        </>
    )
}

const getOptionComponent = (props, option, variant, editing = false) => {

    const handlePointerDown = (event) => {
        editing && event.stopPropagation()
    }

    if (variant == "chip") {
        const primaryColor = option?.primaryColor || "red"
        const secondaryColor = option?.secondaryColor || "pink"
        return (
            <ListItem {...props} style={{padding: 5, margin: 0, justifyContent: "center"}} onPointerDown={handlePointerDown}>
                <Chip 
                    size="small" 
                    label={option?.name} 
                    sx={{ color: primaryColor, border: `${primaryColor} 1px solid`, background: secondaryColor , padding: 0, margin: 0, fontSize: 12}}
                />
            </ListItem>
        )
    } else {
        return (
            <ListItem {...props} style={{padding: 0, margin: 4, fontSize: 12}} onPointerDown={handlePointerDown}>
                <div style={{minHeight: "20px", width: "100%"}}>{option}</div>
            </ListItem>
        )
    }
}