import { createGlobalStyle } from 'styled-components'
import { createTheme } from '@mui/material/styles'

export const GlobalStyle = createGlobalStyle`
    body {
        margin: 0px;
        padding: 0px;
        color: ${props => props.theme == "dark" ? "white" : "#232323"};
        background: ${props => props.theme == "dark" ? "#232323" : "white"};
        font-family: 'Inter Tight', sans-serif;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
  
    input[type="number"] {
        -moz-appearance: textfield;
    }
`

export const baseTheme = createTheme({
    palette: {
        primary: {
            main: "#27C77E",
            light: "#A9E8CB",
            dark: "#168F58",
            contrastText: "#232323",
        },
        secondary: {
            main: "#F2F2F2",
            light: "#F4F4F4",
            dark: "#A9A9A9",
        },
        info: {
            main: "#232323",
            light: "#4f4f4f",
            dark: "#181818",
        },
        success: {
            main: "#27C77E",
            light: "#7DDDB2",
            dark: "#1D76B9",
        },
        warning: {
            main: "#CB7F0E",
            light: "#FFEACB",
            dark: "#C8841E",
        },
        error: {
            main: "#E9695E",
            light: "#F8D0CD",
            dark: "#E34336",
            contrastText: "#232323",
        },
        text: {
            primary: "#232323",
            secondary: "#9E9E9E",
            disabled: "#DDDDDD",
        },
        background: {
            default: "#FFFFFF",
            paper: "#F2F2F2",
            rainbow: `linear-gradient(90deg, #0192FF 0%, #43CF8F 100%)`
        },
    },
    typography: {
        fontFamily: 'Inter Tight, sans-serif',
        button: {
            textTransform: 'none'
        },
    },
    transitions: {
        createMarginOpen: (theme) => theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        createMarginClose: (theme) => theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    components: {
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    border: "none",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: "none"
                },
            },
        },
    }
});

export const darkTheme = createTheme({
    ...baseTheme,
    palette: {
        ...baseTheme.palette,
        mode: "dark",
        info: {
            main: "#F2F2F2",
            light: "#F4F4F4",
            dark: "#A9A9A9",
        },
        text: {
            primary: "#FFFFFF",
            secondary: "#9E9E9E",
            disabled: "#CDCDCD",
        },
        action: {
            active: "#FFFFFF",
            hover: "#F2F2F2",
            selected: "#9E9E9E",
            disabled: "#9E9E9E",
            disabledBackground: "#F2F2F2",
        },
        background: {
            ...baseTheme.palette.background,
            default: "#232323",
            paper: "#464646",
        },
        divider: "#F2F2F2",
    },
    components: {
        ...baseTheme.components,
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#232323",
                    backgroundImage: "none",
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    "&.MuiAutocomplete-option.Mui-focused": {
                        backgroundColor: "#9E9E9E",
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    ".MuiMenuItem-root:hover": {
                        backgroundColor: "#9E9E9E",
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: '#9E9E9E',
                    },
                },
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-root.MuiChip-clickable:hover': {
                        backgroundColor: '#9E9E9E',
                    },
                },
            },
        }
    },
})
