import { ProfileAvatarFromUrl } from "@/common/ProfileAvatar";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";

export default function HighlightsOverview({ registerHighlightsDataFunction }) {
    const [ highlights, setHighlights ] = useState([])

    useEffect(() => {
        registerHighlightsDataFunction(setHighlights)
    })
    
    return (
        <>
            <Grid container spacing={0.4}>
                {highlights.map((highlight, index) => (
                    <Grid item key={index}>
                        <ProfileAvatarFromUrl imageUrl={highlight.profilePic} name={highlight.accountName} size={30}/>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}