import { Grid, IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import { columnProfiles } from "./bidStore"
import { useEffect, useState } from "react"

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';

export const ColumnsMenu = ({isOpen, close, anchorEl, onToggleVisibility, onToggleSticky}) => {
    const controllableColumns = columnProfiles.filter(col=>!col.alwaysVisible)
    const maxPinningReached = controllableColumns.filter(col=>col.sticky).length >= 2
    const maxHiddenReached = controllableColumns.filter(col=>col.visible).length <= 2
    return (
        <Menu open={isOpen} anchorEl={anchorEl} onClose={close}>
            {controllableColumns.map(col=>{
                const disablePinning = maxPinningReached && !col.sticky
                const disableHide = maxHiddenReached && col.visible
                return <MenuItem key={col.id} >
                    <ColumnMenuItem 
                        id={col.id}
                        defaultSticky={col.sticky}
                        defaultVisible={col.visible}
                        label={col.headerText}
                        onToggleVisibility={onToggleVisibility}
                        onToggleSticky={onToggleSticky}
                        disablePinning={disablePinning}
                        disableHide={disableHide}
                    />
                </MenuItem>
            })}
        </Menu>
    )
}

const ColumnMenuItem = ({id, label, defaultVisible, defaultSticky, onToggleVisibility, onToggleSticky, disablePinning, disableHide}) => {
    const [ hidden, setHidden ] = useState(defaultVisible)
    const [ sticky, setSticky ] = useState(defaultSticky)
    useEffect(()=>{
        if (!defaultVisible) {
            columnProfiles.find(col=>col.id == id).visible = defaultVisible
            columnProfiles.find(col=>col.id == id).sticky = defaultSticky
        }
    }, [])
    const handleToggleVisibility = () => {
        const nowHidden = !hidden
        setHidden(nowHidden)
        onToggleVisibility(id, nowHidden)
    }
    const handleToggleSticky = () => {
        const nowSticky = !sticky
        setSticky(nowSticky)
        onToggleSticky(id, nowSticky)
    }
    return (
        <Grid container alignItems={"center"} fontSize={14} justifyContent={"space-between"}>
            <Grid item><span>{label}</span></Grid>
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Tooltip placement="right" title={disableHide && "You reached hide column limit, please show another column first"}>
                            <span>
                                <IconButton size="small" disabled={disableHide} onClick={handleToggleVisibility}>
                                    {hidden ? <VisibilityOutlinedIcon fontSize="tiny"/> : <VisibilityOffOutlinedIcon fontSize="tiny"/>}
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip placement="right" title={disablePinning && "You can only pin 2 columns, please unpin another one first"}>
                            <span>
                                <IconButton disabled={disablePinning} size="small" onClick={handleToggleSticky}>
                                    {sticky ? <PushPinIcon fontSize="tiny" /> : <PushPinOutlinedIcon fontSize="tiny"/>}
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}


