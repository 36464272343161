import { useDispatch } from 'react-redux';
import { request } from "@/Api";
import { updateBid, updateInsights, updateTotals, updateLinesAndGroups, updateLoading, updateAddons, updateBills } from "@/bid/bidSlice";
import { storeBid } from "@/sheet/bidStore"

const useBid = () => {
    const dispatch = useDispatch() 
    const getBid = (id) => {
        dispatch(updateLoading(true))
        request.get(`/bids/${id}`)
        .then((response)=>{
            const bid = response.data
            storeBid(bid)
            dispatch(updateBid(bid))
            dispatch(updateInsights(bid))
            dispatch(updateTotals(bid))
            dispatch(updateLinesAndGroups(bid.line_items_grouped))
            dispatch(updateAddons(bid.addons))
            dispatch(updateBills(bid.bills))
            dispatch(updateLoading(false))
        })
    }
    return getBid
}

export { useBid }
