import { request } from "@/Api"
import { CustomBox } from "@/common/StyledComponents"
import { Autocomplete, Button, Grid, IconButton, Modal, TextField, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { store } from "./bidStore"
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

export default function AddonForm({addonData, onAddonChange, onAddonBlur, onAddonDelete}) {
    const [ addon, setAddon ] = useState()
    const lineGroup = addon && store.bid.lineGroups.find(group=>group.id == addon.line_group_id)
    let amount = addon && addon.amount
    amount = amount==null ? 0 : ((addon.type == "amount") ? addon.amount : addon.amount*100)
    const lineGroupType = lineGroup ? (lineGroup.parent_id ? "category" : "section") : "bid"
    const lineGroupText = `${lineGroupType}: ${lineGroup ? lineGroup.name : store.bid.name}`

    const handleAddonBlur = (field) => (e) => {
        onAddonBlur && onAddonBlur(field, e.target.value)
    }

    const handleInputChange = (field) => (e) => {
        setAddon({...addon, [field]: e.target.value})
        onAddonChange && onAddonChange(field, e.target.value)
    }

    const handleAmountChange = (e) => {
        const val = e.target.value
        const valCalculated = addon.type == "amount" ? val : (val/100).toFixed(2)
        setAddon({...addon, amount: valCalculated})
        onAddonChange && onAddonChange("amount", valCalculated)
    }

    const handleAmountBlur = (e) => {
        const val = e.target.value
        const valCalculated = addon.type == "amount" ? val : (val/100).toFixed(2)
        onAddonBlur && onAddonBlur("amount", valCalculated)
    }

    const handleToggleButtonChange = (field) => (e, newValue) => {
        if (addon[field] == newValue || !newValue) {
            return
        }
        setAddon({...addon, [field]: newValue})
        onAddonChange && onAddonChange(field, newValue)
        onAddonBlur && onAddonBlur(field, newValue)
    }

    const handleAddonDelete = () => {
        onAddonDelete && onAddonDelete(addon.id)
    }

    useEffect(()=>{
        setAddon(addonData)
    }, [addonData])

    if (!addon) {
        return
    }
    return (
        <div>
            <Grid container justifyContent={"space-between"} wrap="nowrap" spacing={1} alignItems={"center"} paddingBottom={2}>
                <Grid item flexGrow={1} flexBasis={1000}>
                    <TextField
                        fullWidth
                        size="small"
                        placeholder="Name..."
                        value={addon.name || ""}
                        onInput={handleInputChange("name")}
                        onBlur={handleAddonBlur("name")}
                    />
                </Grid>
                <Grid item minWidth={180}>
                    <TextField
                        size="small"
                        placeholder="0"
                        value={lineGroupText}
                        disabled
                        onInput={handleInputChange("name")}
                        onBlur={handleAddonBlur("name")}
                    />
                </Grid>
                <Grid item minWidth={90}>
                    <ToggleButtonGroup
                        color="success"
                        size="small"
                        value={addon.type || "amount"}
                        exclusive
                        onChange={handleToggleButtonChange("type")}
                    >
                        <ToggleButton value={"amount"}>
                            <div style={{paddingLeft: 8, paddingRight: 8}}>$</div>
                        </ToggleButton>
                        <ToggleButton value={"percentage"}>
                            <div style={{paddingLeft: 8, paddingRight: 8}}>%</div>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item flexGrow={1} flexBasis={100} minWidth={180}>
                    <TextField
                        size="small"
                        placeholder="0"
                        value={amount ? parseFloat(amount).toFixed(0) : ""}
                        onInput={handleAmountChange}
                        onBlur={handleAmountBlur}
                        inputProps={{style: {textAlign: addon.type == "amount" ? "left" : "right"}}}
                        InputProps={{
                            startAdornment: addon.type == "amount" && <span style={{paddingRight: 10}}> $ </span>,
                            endAdornment: addon.type == "percentage" &&  <span style={{paddingLeft: 10}}> % </span>
                        }}
                    />
                </Grid>
                <Grid item maxWidth={50}>
                    <IconButton onClick={handleAddonDelete}>
                        <DeleteOutlineRoundedIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    )
}

export function AddonPopup({isOpen, close, onPopupConfirm, lineGroupId = null, addonData = null}) {
    const bidId = store.bid.id
    const theme = useTheme()
    const [ addon, setAddon ] = useState({})
    const handleConfirmAddon = () => { // TODO: take out the api request responsibility from this component, but the challenge is handling internal states by async functions from outside (perhaps consider using await)
        if (addon.id) {
            request.put(`bids/${bidId}/addon/${addon.id}`, addon)
            .then(_=>{
                close()
            })
        } else {
            request.post(`/bids/${bidId}/addon`, addon)
            .then(_=>{
                close()
            })
        }
    }
    const handleAddonChange = (field, value) => {
        setAddon({...addon, [field]: value})
    }
    useEffect(()=>{
        setAddon({line_group_id: lineGroupId, bid_id: bidId, type: addon.type || "amount", ...addonData})
    }, [lineGroupId, addonData, isOpen])

    return (
        <Modal open={isOpen} keepMounted={false} onClose={close}>
            <CustomBox sx={{ background: theme.palette.background.default }}>
                <h2>{`${addon.id ? "Edit" : "Create a new"} add-on`}</h2>

                <AddonForm 
                    addonData={addon}
                    onAddonChange={handleAddonChange}
                />
                <Grid container spacing={1} justifyContent={"right"}>
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={close}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={handleConfirmAddon}>
                            {`${addon.id ? "Update" : "Create"} Add-on`}
                        </Button>
                    </Grid>
                </Grid>
            </CustomBox>
        </Modal>
    )
}