import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { parse, format } from "date-fns"
import { NumericFormat } from "react-number-format"
import styled from "styled-components"

import { useTheme } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2'
import InputLabel from "@mui/material/InputLabel"
import TextField from "@mui/material/TextField"
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined'
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined"

import ApiAutocomplete from "@/common/ApiAutocomplete"
import { ShadedBox, Title } from '@/common/StyledComponents'
import { CUSTOM_BILLING_SCHEDULE } from "@/project/constants"
import { setError } from "@/nav/navSlice"
import { request } from "@/Api"
import { getCurrencySymbol } from '@/utils'
import { getClientCurrency, store } from "@/sheet/bidStore"

const Bills = styled.div`
    margin-top: 24px;
`

export default function BidBills({ bidId, quoted, legacy }) {
    const theme = useTheme()
    const dispatch = useDispatch()

    const [localDescription, setLocalDescription] = useState("")
    const [localBills, setLocalBills] = useState([])
    const [requiresSave, setRequiresSave] = useState(null)
    const [errors, setErrors] = useState({})

    const bills = store.bid.bills
    const financialTerm = store.bid.financial_term
    const currency = getClientCurrency()
    const project = store.bid.project

    const isCustom = financialTerm ? financialTerm.is_custom : false

    useEffect(() => {
        setLocalDescription(financialTerm ? financialTerm.description : "")
    }, [financialTerm])

    useEffect(() => {
        setLocalBills(bills.map(bill => ({ ...bill })))
    }, [bills])

    useEffect(() => {
        if (requiresSave != null) {
            saveBill(requiresSave)()
        }
    }, [requiresSave])

    const clearError = (billId, field) => {
        if (errors[billId] && errors[billId][field]) {
            const { [field]: _removed1, ...remainingErrors } = errors[billId]
            if (Object.keys(remainingErrors).length) {
                setErrors({
                    ...errors,
                    [billId]: remainingErrors,
                })
            } else {
                const { [billId]: _removed2, ...remainingErrors} = errors
                setErrors({
                    ...remainingErrors,
                })
            }
        }
    }

    const createBill = (percentage) => {
        percentage = percentage || 0
        return {
            "quoted_percentage": percentage,
            "actual_amount": percentage * quoted,
            "bill_date": null,
            "notes": "",
            "project_id": project.id,
            "bid_id": bidId,
        }
    }

    const saveBill = (index) => () => {
        setRequiresSave(null)

        const bill = bills[index]
        const localBill = localBills[index]

        if(JSON.stringify(bill) === JSON.stringify(localBill)) {
            return
        }

        return request.put(`/bills/${localBill.id}`, localBill).catch(error => {
            const detail = error.response.data.detail[0]
            setErrors({
                ...errors,
                [localBill.id]: {
                    ...errors[localBill.id],
                    [detail.loc[1]]: detail.msg,
                }
            })
        })
    }

    const saveCustomTerms = (description, newBills = []) => {
        request.post(`/financial-terms`, { 
            id: null,
            name: CUSTOM_BILLING_SCHEDULE,
            description: description,
            billing_schedule: null,
            is_custom: true,
        }).then((response)=>{
            request.patch(`/bids/${bidId}`, {financial_term_id: response.data.id, bills: newBills}).catch(() => {
                dispatch(setError({ message: "Failed to update bid financial term." }))
            })
        }).catch(() => {
            dispatch(setError({ message: "Failed to create custom financial term." }))
        })
    }

    const updateCustomTerms = () => {
        if(localDescription == financialTerm.localDescription) {
            return
        }

        if (isCustom && financialTerm.id) {
            request.put(`/financial-terms/${financialTerm.id}`, {
                ...financialTerm,
                description: localDescription,
            })
        } else {
            saveCustomTerms(localDescription, bills)
        }
    }

    const handleTermsChange = (e, term) => {
        const schedule = term ? term.billing_schedule : null
        const newBills = []
        if (schedule && !legacy) {
            const percentages = term.is_custom ? [0] : schedule.split("/")
            for (var i in percentages){
                const percentage = parseFloat(percentages[i]) / 100
                newBills.push(createBill(percentage))
            }
        }        
        if (term.public || !term.id) {
            saveCustomTerms(term.description, newBills)
        } else {
            request.patch(`/bids/${bidId}`, {financial_term_id: term.id, bills: newBills}).catch(() => {
                dispatch(setError({ message: "Failed to update bid billing schedule." }))
            })
        }
    }

    const handleDescriptionChange = (event) => {
        setLocalDescription(event.target.value)
    }

    const handleAddBill = () => {
        const bill = createBill()
        setLocalBills([...localBills, bill])

        request.post(`/bills`, bill).catch(() => {
            dispatch(setError({ message: "Failed to add new bill." }))
        })
    }

    const handleDeleteBill = (billId, index) => () => {
        if (!billId) {
            return
        }

        request.delete(`/bills/${billId}`).catch(() => {
            dispatch(setError({ message: "Failed to delete bill." }))
        })
    }

    const handlePercentageChange = (billId) => (values, sourceInfo) => {
        if (!billId || sourceInfo.source == "prop") {
            return
        }

        const value = values.value
        const amount = quoted * value
        setLocalBills(localBills.map(bill => bill.id == billId ? {
            ...bill,
            quoted_percentage: value,
            actual_amount: amount,
        } : bill ))
        clearError(billId, "quoted_percentage")
    }

    const handleActualChange = (billId) => (values, sourceInfo) => {
        if (!billId || sourceInfo.source == "prop") {
            return
        }

        const value = values.value
        setLocalBills(localBills.map(bill => bill.id == billId ? {
            ...bill,
            quoted_percentage: 0,
            actual_amount: value,
        } : bill ))
        clearError(billId, "actual_amount")
    }

    const handleCalendarChange = (billId) => (newValue) => {
        if (!billId) {
            return
        }

        let date = ''
        try {
            date = format(newValue, "y-MM-dd")
        } catch (e) {
            // Keep empty string if invalid date
        }

        setLocalBills(localBills.map(bill => bill.id == billId ? { ...bill, bill_date: date } : bill ))
        clearError(billId, "bill_date")
    }

    const handleNotesChnage = (billId) => (event) => {
        setLocalBills(localBills.map(bill => bill.id == billId ? { ...bill, notes: event.target.value } : bill ))
        clearError(billId, "notes")
    }

    return (
        <ShadedBox backgroundColor={theme.palette.background.paper}>
            <Title margin="0 0 12px 0" bold>Billing</Title>

            <InputLabel>Financial Terms & Billing Schedule</InputLabel>
            <ApiAutocomplete
                value={financialTerm}
                apiRoute={"financial-terms"}
                fields={["name"]}
                additionalOptions={isCustom ? [{...financialTerm}] : [{ 
                    id: null,
                    name: CUSTOM_BILLING_SCHEDULE,
                    description: "Financial Terms...",
                    billing_schedule: null,
                    is_custom: true,
                }]}
                onChange={handleTermsChange}
                disabled={bills.some(bill => bill.sent)}
                isOptionEqualToValue={(option, value) => (value.id == option.id || (value.is_custom && option.is_custom))}
                fullOptionData
                silentErrors
                fullWidth
                blurOnSelect
            />

            {financialTerm ? (
                <TextField
                    value={localDescription}
                    onChange={handleDescriptionChange}
                    onBlur={updateCustomTerms}
                    multiline
                    fullWidth
                    sx={{margin: "24px auto"}}
                />
            ) : null}

            {!legacy && (localBills.length || isCustom) ? (
                <Bills>
                    <Grid container spacing={3}>
                        {isCustom ? <Grid item xs={0.5} /> : null}
                        <Grid item xs={1.5}>
                            <InputLabel>Bill Percentage</InputLabel>
                        </Grid>
                        <Grid item xs={3.5}>
                            <InputLabel>Amount</InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel>Bill Date</InputLabel>
                        </Grid>
                        <Grid item xs={isCustom ? 3.5 : 4}>
                            <InputLabel>Notes</InputLabel>
                        </Grid>
                    </Grid>

                    {localBills.map((bill, index) => (
                        <Grid container spacing={3} alignItems="flex-start" key={`bill_${index}`}>
                            {isCustom && (
                                <Grid item xs={0.5} textAlign="right" sx={{paddingTop: "20px"}}>
                                    <IconButton
                                        onClick={handleDeleteBill(bill.id, index)}
                                        disabled={bill.sent.toString()}
                                    >
                                        <RemoveCircleOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                            )}
                            <Grid item xs={1.5}>
                                <NumericFormat
                                    customInput={TextField}
                                    value={bill.quoted_percentage}
                                    sx={{width:"100%"}}
                                    inputProps={{
                                        style: {textAlign: "right"},
                                    }}
                                    onValueChange={handlePercentageChange(bill.id)}
                                    onBlur={saveBill(index)}
                                    suffix="%"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    convertToPercentage
                                    disabled={!isCustom || !bill.id}
                                />
                            </Grid>
                            <Grid item xs={3.5}>
                                <NumericFormat
                                    customInput={TextField}
                                    value={bill.actual_amount}
                                    sx={{width:"100%"}}
                                    inputProps={{
                                        style: {textAlign: "right"}
                                    }}
                                    onValueChange={handleActualChange(bill.id)}
                                    onBlur={saveBill(index)}
                                    prefix={getCurrencySymbol(currency || "CAD")}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    disabled={!isCustom}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={bill.bill_date ? parse(bill.bill_date, "y-MM-dd", new Date()) : null}
                                        inputFormat={"yyyy-MM-dd"}
                                        onChange={handleCalendarChange(bill.id)}
                                        onAccept={() => setRequiresSave(index)}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                label=" "
                                                InputLabelProps={{shrink: false}}
                                                onBlur={saveBill(index)}
                                                error={errors[bill.id] && "bill_date" in errors[bill.id]}
                                                helperText={errors[bill.id] ? errors[bill.id]["bill_date"] : ""}
                                            />
                                        )}
                                        disabled={!bill.id}
                                        disablePast
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={isCustom ? 3.5 : 4}>
                                <TextField
                                    value={bill.notes}
                                    onChange={handleNotesChnage(bill.id)}
                                    onBlur={saveBill(index)}
                                    fullWidth
                                    disabled={!bill.id}
                                />
                            </Grid>
                        </Grid>
                    ))}

                    {isCustom ? (
                        <Button
                            onClick={handleAddBill}
                            startIcon={<AddCircleOutlinedIcon />}
                            color="info"
                            sx={{margin: "16px 5px"}}
                        >
                            Add bill
                        </Button>
                    ) : null}
                </Bills>
            ) : null}

        </ShadedBox>
    )
}
