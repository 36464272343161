import { useEffect, useRef, useState } from "react"
import { ReactGrid } from "@silevis/reactgrid"

import { getColumns, reorderArray } from "./sheetUtils"
import { bidToRows, columnProfiles, fitColumnWidthsToContainer, getLineItemsHash, getProcessedColumnProfiles, getReadOnly, getRootAddons, getStyledRows, store, storeBid } from "./bidStore"

import "./styles.scss"
import "./testStyles.css"

import { request } from "@/Api";
import { SOCKET_BASE_URL } from "@/config"
import { useSelector } from "react-redux"
import { selectHasPermission, selectToken } from "@/auth/authSlice"
import { ADDON_ROW_ID_PREFIX, LINE_GROUP_ROW_ID_PREFIX, LINE_ITEM_ROW_ID_PREFIX, rateTypes, resourceTypes, ROW_CELL_HEIGHT } from "./constants"
import { getServiceItems } from "./serviceItemsStore"
import { Button, Divider, Grid, IconButton, Menu, MenuItem, Paper, Skeleton, useTheme } from "@mui/material"
import { downloadFile, generateRandomColor, getComplementarySet } from "@/utils"

import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

import { FormulaCellTemplate } from "./FormulaCellTemplate"
import { tryParseFormula } from "./formulaParser"
import CustomChevronCellTemplate from "./CustomChevronCellTemplate"
import BidHeader from "@/sheet/BidHeader"
import BidSum from "@/sheet/BidSum"
import LineGroupMenu from "./LineGroupMenu"
import BidBills from "@/sheet/BidBills"
import BidNotes from "@/sheet/BidNotes"
import { AddonPopup } from "./AddonForm"
import AddonsForm from "./AddonsForm"
import Comments from "@/common/Comments"
import BidActivity from "@/sheet/BidActivity"
import HighlightsOverview from "@/sheet/HighlightsOverview"
import { useParams } from "react-router-dom"
import CustomDropdownCellTemplate from "./CustomDropdownCellTemplate"
import { ColumnsMenu } from "./ColumnsMenu"
import styled from 'styled-components'
import { currencies } from "@/constants"
import { SearchBox } from "@/common/StyledComponents"
import TopSheetButton from "./TopSheetButton"

const ReactGridContainer = styled.div`
    ::-webkit-scrollbar {
        display:none;
    }
`

const highlightColor = generateRandomColor()
let lastScrollTop = 0

const isRowFullyExpanded = (rowsHash, row) => {
    let currentRow = row
    let chevron = getChevronCellFromRow(currentRow)
    if (!chevron) {
        return true
    }
    while (currentRow && chevron && chevron.parentId != null) {
        currentRow = rowsHash[chevron.parentId]
        chevron = getChevronCellFromRow(currentRow)
        if (!chevron.isExpanded) {
            return false
        }
    }
    return true
}

const getVisibleRows = (rows) => {
    const rowsHash = rowsToHashTable(rows)
    return rows.filter(row => isRowFullyExpanded(rowsHash, row))
}

const getSearchedLineItemRows = (rows, searchString) => {
    const visibleGroupsSet = new Set()
    if (!searchString) {
        return rows
    }
    const searchedRows = rows.filter((row, index) => {
        if (index == 0) {
            return true
        }
        const [rowTypePrefix, lineId] = row.rowId.split("-")
        if (!(rowTypePrefix == LINE_ITEM_ROW_ID_PREFIX || rowTypePrefix == ADDON_ROW_ID_PREFIX)) {
            return true
        }
        const nameCell = getNameFieldCellFromRow(row)
        if (!nameCell) {
            return true
        }
        const searched = nameCell.text.toLowerCase().includes(searchString.toLowerCase())
        if (searched) {
            visibleGroupsSet.add(nameCell.parentId)
        }
        return searched
    })

    const categoryGroupsList = Array.from(visibleGroupsSet)
    categoryGroupsList.forEach(categoryRow=>{
        if (categoryRow.parentId) {
            visibleGroupsSet.add(categoryRow.parentId)
        }
    })
    const visibleLineAndGroupRows = searchedRows.filter((row, index)=>{
        if (index == 0) {
            return true
        }
        const [rowTypePrefix, groupId] = row.rowId.split("-")
        if (rowTypePrefix == LINE_ITEM_ROW_ID_PREFIX || rowTypePrefix == ADDON_ROW_ID_PREFIX) {
            return true
        }
        return visibleGroupsSet.has(row.rowId)
    })
    return visibleLineAndGroupRows
}

const getChevronCellFromRow = (row) => {
    return row.cells.find(cell=>cell.type == "chevron" || cell.renderer != null) // don't generalize what a cheveron row is
}

const getNameFieldCellFromRow = (row) => {
    return row.cells.find(cell=>cell.columnId == "name") // don't generalize what a cheveron row is
}

const rowsToHashTable = (rows) => {
    return Object.fromEntries(rows.map(row => [row.rowId, row]))
}

export default function BidSheet(){  
    const { BID_ID } = useParams() // TODO: use path param
    const theme = useTheme()
    const themeMode = theme.palette.mode || "light"

    const [ rows, setRows ] = useState([])
    const [ columns, setColumns ] = useState([])
    const [ columnRightStickyCount, setColumnRightStickyCount ] = useState([])
    const [ expandAll, setExpandAll ] = useState(false)
    const [ columnMenuOpen, setColumnMenuOpen ] = useState(false)
    const [ lineGroupPopupData, setLineGroupPopupData] = useState(null)
    const [ lineGroupMenuAnchor, setLineGroupMenuAnchor ] = useState(null)

    const [ addonPopupOpen, setAddonPopupOpen ] = useState(false)
    const [ addonPopupData, setAddonPopupData ] = useState(null)

    const [ searchString, setSearchString ] = useState("")
    const [ highlights, setHighlights ] = useState([])

    const [ quotedTotal, setQuotedTotal ] = useState(0)

    const socket = useRef()
    const columnMenuAnchor = useRef()
    const token = useSelector(selectToken)
    const canViewConfidentialData = useSelector(state => selectHasPermission(state, "view_confidential_data"))
    const rowsToRender = getVisibleRows(rows)
    const rowsSearched = getSearchedLineItemRows(rowsToRender, searchString)

    const reactGridRef = useRef()
    const gridAndSettingRef = useRef()

    const readOnly = getReadOnly()
    const rootAddons = getRootAddons()

    const isTemplate = store.bid.project_id == null
    // workaround for child state control, should be refactored because inverted data flow
    const setHighlightsRef = useRef()
    const setSumDataRef = useRef()

    const registerChildHighlightsFunction = (func) => {
        setHighlightsRef.current = func
    }
    const registerChildSumDataFunction = (func) => {
        setSumDataRef.current = func
    }

    const sendSocketMessage = (payload) => {
        socket.current.send(JSON.stringify(payload))
    }

    const handleSocketClose = () => {
        // location.reload()
    }

    const setRowsFromBid = () => {
        const bidRows = bidToRows()
        const styledBidRows = getStyledRows(bidRows, themeMode)
        setRows(styledBidRows)
    }

    const refreshBid = (bid) => {
        let oldBid = {...store.bid}
        storeBid(bid)
        let newBid = {...store.bid}
        if (oldBid.id) {
            handleNewGroupExpand(oldBid, newBid)
        }
        setRowsFromBid()
        setQuotedTotal(bid.total_quoted)
    }

    const handleNewGroupExpand = (oldBid, newBid) => {
        // TODO: find better ways to handle new object ids
        // perhaps have add button callback invoke state refresh
        // or perhaps refactor socket payload to include relevant data
        // perhaps returning data of relevant module is not just a preference, but a necessity 
        const oldGroupIds = oldBid.lineGroups.map(group=>group.id)
        const newGroupsIds = newBid.lineGroups.map(group=>group.id)
        const newGroupIds = getComplementarySet(newGroupsIds, oldGroupIds)
        if ((oldGroupIds.length == newGroupsIds.length) || newGroupIds.length == 0){
            return
        }
        const newGroupId = newGroupIds[0]
        store.localData.expantions[`group-${newGroupId}`] = true
        setRowsFromBid()
    }

    const handleSocketData = (event) => {
        const payloadParsed = JSON.parse(event.data)
        const payloadType = payloadParsed.type
        const data = payloadParsed.data
        if (payloadType == "bid") {
            refreshBid(data)
        } else if (payloadType == "highlight") {
            setHighlights(data)
            setHighlightsRef.current(data)
        } else {
            
        }
    }

    const getBid = () => {
        request.get(`/bids/${BID_ID}`)
        .then(response=>{
            refreshBid(response.data)
        })
    }

    const updateLineItem = (id, field, val) => {
        const line = store.bid.lineItems.find(line=>line.id == id)
        request.put(`bids/${BID_ID}/line-items/${id}`, {...line, [field]: val})
    }

    const updateLineGroup = (id, field, val) => {
        const group = store.bid.lineGroups.find(group=>group.id == id)
        request.put(`bids/${BID_ID}/line-groups/${id}`, {...group, [field]: val})
    }

    const updateAddon = (id, field, val) => {
        const addon = store.bid.addons.find(addon=>addon.id == id)
        request.put(`bids/${BID_ID}/addon/${id}`, {...addon, [field]: val})
    }

    const orderChildren = (parentId, childrenIds) => {
        if (parentId) {
            request.put(`/bids/${BID_ID}/line-groups/${parentId}/order`, childrenIds)
        } else {
            request.put(`/bids/${BID_ID}/line-groups/order`, childrenIds)
        }
    }

    useEffect(()=>{
        if (store.bid.id) {
            setRowsFromBid()
        }
    }, [themeMode])

    if (socket.current){
        socket.current.onmessage = handleSocketData // NOTE: redefine the function per render to prevent closure
    }
    useEffect(()=>{
        getBid()
        socket.current = new WebSocket(`${SOCKET_BASE_URL}/ws/bid/${BID_ID}?token=${token}`)
        socket.current.onclose = handleSocketClose
        getServiceItems()
        handleColumnSettingToggle()
    }, [])

    useEffect(()=>{
        if (reactGridRef.current) {
            fitColumnWidthsToContainer(reactGridRef.current)
            setColumns(getColumns(getProcessedColumnProfiles()))
        }
    }, [reactGridRef])

    useEffect(()=>{
        window.addEventListener("scroll", handleScroll)
    }, [])

    const handleScroll = (e) => {
        const g = reactGridRef.current
        const gs = gridAndSettingRef.current
        const currentScrollTop = document.documentElement.scrollTop
        const deltaY = currentScrollTop - lastScrollTop
        if (deltaY > 0) {
            if (gs.getBoundingClientRect().top <= 0) {
                g.scrollBy(0, deltaY)
            }
        } else if (deltaY < 0) {
            if (gs.getBoundingClientRect().bottom == window.innerHeight) {
                g.scrollBy(0, deltaY)
            }
            else {
                if (g.scrollTop != 0 && gs.getBoundingClientRect().bottom > window.innerHeight){
                    g.scrollTo({
                        behavior: "smooth",
                        top: 0}
                    )
                }
            }
        }
        lastScrollTop = currentScrollTop
    }
    
    const handleColumnSettingToggle = () => {
        const processedProfiles = getProcessedColumnProfiles()
        setColumns(getColumns(processedProfiles))
        setColumnRightStickyCount(processedProfiles.filter(col=>col.sticky).length)
    }

    const handleCellChanged = (changes) => {
        changes.forEach(change=>{
            localCellChangeHandler(change)
            customCellChangeHandler(change)
        })
    } 

    const localCellChangeHandler = ({ columnId, newCell, previousCell, rowId, type }) => {
        if (type === "dropdown") {
            if (previousCell.isOpen !== newCell.isOpen) {
                if (newCell.isOpen) {
                    store.localData.openedDropdownCell.rowId = rowId
                    store.localData.openedDropdownCell.columnId = columnId
                } else {
                    store.localData.openedDropdownCell.rowId = null
                    store.localData.openedDropdownCell.columnId = null
                }
            }
            setRowsFromBid()
        } else {
            const valueOrTextChanged = newCell.text != previousCell.text || (newCell.value != previousCell.value && !isNaN(newCell.value))
            if (valueOrTextChanged) {
                const newRows = [...rows];
                const changeRowIdx = rows.findIndex((el) => el.rowId === rowId);
                const changeColumnIdx = columns.findIndex((el) => el.columnId === columnId)
                newRows[changeRowIdx].cells[changeColumnIdx] = newCell;
                setRows(newRows);
            }
        }
    }

    const customCellChangeHandler = ({ columnId, newCell, previousCell, rowId }) => {
        if (newCell.text != previousCell.text) {
            const modelType = rowId.split("-")[0]
            const modelId = rowId.split("-")[1]
            if (newCell.text.startsWith && newCell.text.startsWith("=")) {
                newCell.value = tryParseFormula(newCell.text)
            }

            let value

            const isTextCell = newCell.type == "customChevron" || newCell.type == "text" || newCell.type == "dropdown" || newCell.type.includes("customDropdown")
            const isNumberCell = newCell.type == "simpleFormula" || newCell.type == "number" || newCell.type == "simpleFormulaOneAsEmpty"
            const isCheckboxCell = newCell.type == "checkbox"

            if (isNumberCell) {
                value = newCell.value
            } else if (isTextCell) {
                value = newCell.text
                if (columnId == "is_makers_fees") {
                    value = newCell.text == "Internal"
                }
            } else if (isCheckboxCell) {
                value = newCell.text == "1"
            }

            if (modelType == LINE_ITEM_ROW_ID_PREFIX){
                updateLineItem(modelId, columnId, value)
            } else if (modelType == LINE_GROUP_ROW_ID_PREFIX) {
                updateLineGroup(modelId, columnId, value)
            } else if (modelType == ADDON_ROW_ID_PREFIX) {
                updateAddon(modelId, columnId, value)
            }
        }
    }

    // TODO: consider other approaches of ordering: decimal ordering, linked list etc
    const handleRowsReorder = (targetRowId, rowIds) => {  
        if (rowIds.length > 1) {
            return
        }      
        const lineItems = Object.fromEntries(store.bid.lineItems.map(line=>[line.id, line]))
        const lineGroups = Object.fromEntries(store.bid.lineGroups.map(group=>[group.id, group]))

        const [sourceIdPrefix, sourceIdString] = rowIds[0].split("-")
        const [_targetIdPrefix, targetIdString] = targetRowId.split("-")
        const sourceId = parseInt(sourceIdString)
        const targetId = parseInt(targetIdString)

        const isOrderingLine = sourceIdPrefix == LINE_ITEM_ROW_ID_PREFIX
        const isOrderingGroup = sourceIdPrefix == LINE_GROUP_ROW_ID_PREFIX

        let sourceParentId
        let targetParentId

        let sourceSiblings = []
        let targetSiblings = []

        let overallSourceIndex
        let overallTargetIndex

        if (isOrderingLine) {
            sourceParentId = lineItems[sourceId].line_group_id
            targetParentId = lineItems[targetId].line_group_id
            sourceSiblings = lineGroups[sourceParentId].line_items
            targetSiblings = lineGroups[targetParentId].line_items
            overallSourceIndex = store.bid.lineItems.map(l=>l.id).indexOf(sourceId)
            overallTargetIndex = store.bid.lineItems.map(l=>l.id).indexOf(targetId)
        } else if (isOrderingGroup) {
            sourceParentId = lineGroups[sourceId].parent_id
            targetParentId = lineGroups[targetId].parent_id
            sourceSiblings = sourceParentId ? lineGroups[sourceParentId].children : store.bid.line_items_grouped
            targetSiblings = targetParentId ? lineGroups[targetParentId].children : store.bid.line_items_grouped
            overallSourceIndex = store.bid.lineGroups.map(l=>l.id).indexOf(sourceId)
            overallTargetIndex = store.bid.lineGroups.map(l=>l.id).indexOf(targetId)
        }

        const isSortingDownwards = overallSourceIndex < overallTargetIndex
        const sameParent = sourceParentId == targetParentId

        const targetSiblingIds = targetSiblings.map(item=>item.id)
        const sourceSiblingIds = sourceSiblings.map(item=>item.id)

        const newSourceSiblingIds = sourceSiblingIds.filter(id=>id!=sourceId)
        if (sameParent) {
            const targetIndex = newSourceSiblingIds.indexOf(targetId)
            newSourceSiblingIds.splice(isSortingDownwards ? targetIndex + 1 : targetIndex, 0, sourceId)
            orderChildren(sourceParentId, newSourceSiblingIds)
        } else {
            orderChildren(sourceParentId, newSourceSiblingIds)
            const targetIndex = targetSiblingIds.indexOf(targetId)
            targetSiblingIds.splice(isSortingDownwards ? targetIndex + 1 : targetIndex, 0, sourceId)
            orderChildren(targetParentId, targetSiblingIds)
        }
    }
    
    const handleLocalRowsReorder = (targetRowId, rowIds) => {
        setRows((prevRows) => {
            const to = rows.findIndex(row => row.rowId === targetRowId);
            const rowsIds = rowIds.map((id) => rows.findIndex(row => row.rowId === id));
            return reorderArray(prevRows, rowsIds, to);
        })
    }

    const handleCanReorderRows = (targetRowId, rowIds) => {
        // TODO: check for leaf groups instead
        if (rowIds.length > 1) {
            return
        } 
        const rowsHash = rowsToHashTable(rows)
        return rowIds.every(rowId => rowsHash[rowId].indent == rowsHash[targetRowId].indent)
    }

    const handleSelectionChanged = (ranges) => {
        // NOTE: invokes on both mouse up and down
        handleSelectionSummary(ranges) // TODO: Isolate state
        handeSelectionHighlight(ranges)
    }

    const handeSelectionHighlight = (ranges) => {
        const selection = ranges[0].first
        sendSocketMessage(
            {
                type: "highlight",
                data: {rowId: selection.row.rowId, columnId: selection.column.columnId, borderColor: highlightColor}
            }
        )
    }

    const handleSelectionSummary = (ranges) => {
        let selectedSum = 0
        let selectedCount = 0
        let selectedNumCount = 0
        let selectedAverage = 0
        ranges.forEach(range=>{
            const colIdxStart = range.columns[0].idx
            const colIdxEnd = range.columns[range.columns.length-1].idx + 1
            range.rows.forEach(row=>{
                const selectedCells = row.cells.slice(colIdxStart, colIdxEnd)
                selectedCells.forEach(cell=>{
                    if (!isNaN(cell.value)) {
                        selectedSum += parseFloat(cell.value)
                        selectedNumCount += 1
                    }
                    selectedCount += 1 
                })
            })
        })
        selectedAverage = selectedSum / selectedNumCount
        setSumDataRef.current && setSumDataRef.current({selectedSum: selectedSum.toFixed(2), selectedCount, selectedAverage: selectedAverage.toFixed(2)})
    }

    const handleToggleExpandAll = () => {
        const nowExpandAll = !expandAll
        store.bid.lineGroups.map(group=>{
            store.localData.expantions[`group-${group.id}`] = nowExpandAll
        })
        setExpandAll(nowExpandAll)
        setRowsFromBid()
    }

    const handleColumnToggleVisibility = (id, isVisible) => {
        columnProfiles.find(col=>col.id == id).visible = isVisible
        fitColumnWidthsToContainer(reactGridRef.current)
        handleColumnSettingToggle()
        setRowsFromBid()
    }

    const handleColumnToggleSticky = (id, isSticky) => {
        columnProfiles.find(col=>col.id == id).sticky = isSticky
        handleColumnSettingToggle()
        setRowsFromBid()
    }

    const handleExpandRow = (groupRowId, expanded) => {
        store.localData.expantions[groupRowId] = expanded
        setRowsFromBid()
    }

    const handleOpenLineGroupMenu = (groupRowId, anchor) => {
        setLineGroupMenuAnchor(anchor.current)
        const groupId = parseInt(groupRowId.split("-")[1])
        setLineGroupPopupData(store.bid.lineGroups.find(group=>group.id==groupId))
    }

    const handleSectionLinkClick = (sectionId) => {
        const rowId = `group-${sectionId}`
        const rowIndex = rows.findIndex(row=>row.rowId == rowId)
        const rowHeightPixel = rowIndex * ROW_CELL_HEIGHT - ROW_CELL_HEIGHT
        if (reactGridRef.current) {
            reactGridRef.current.scrollIntoView({
                behavior: "smooth",
            })
            reactGridRef.current.scrollTo({
                behavior: "smooth",
                top: rowHeightPixel
            })
        }
        handleExpandRow(rowId, true)
    }

    const handleDeleteRow = (rowId) => {
        // TODO: implement async waiting feedback
        const [ rowTypePrefix, modelId ] = rowId.split("-")
        if (rowTypePrefix == ADDON_ROW_ID_PREFIX) {
            request.delete(`bids/${BID_ID}/addon/${modelId}`)
        } else if (rowTypePrefix == LINE_ITEM_ROW_ID_PREFIX) {
            request.delete(`bids/${BID_ID}/line-items/${modelId}`)
        } else if (rowTypePrefix == LINE_GROUP_ROW_ID_PREFIX) {
            request.delete(`bids/${BID_ID}/line-groups/${modelId}`)
        }
    }

    const handleCopyRow = (rowId) => {
         // TODO: implement async waiting feedback
        const [ rowTypePrefix, modelId ] = rowId.split("-")
        if (rowTypePrefix == ADDON_ROW_ID_PREFIX) {
            const addonData = store.bid.addons.find(addon=>addon.id == modelId)
            request.post(`bids/${BID_ID}/addons`, addonData)
        } else if (rowTypePrefix == LINE_ITEM_ROW_ID_PREFIX) {
            const lineItemData = store.bid.lineItems.find(line=>line.id == modelId)
            request.post(`bids/${BID_ID}/line-items`, lineItemData)
        } else if (rowTypePrefix == LINE_GROUP_ROW_ID_PREFIX) {
            request.post(`bids/${BID_ID}/line-groups/${modelId}/copy`)
        }
    }

    const handleCleanBid = () => {
        request.put(`/bids/${BID_ID}/clean`)
    }

    const handleExport = () => {
        downloadFile(`bids/${BID_ID}/csv-export`)
    }

    const handleAddonPopupOpen = (addonId) => {
        const addon = store.bid.addons.find(addon=>addon.id == addonId)
        if (addon) {
            setAddonPopupOpen(true)
            setAddonPopupData(addon)
        }
    }

    const handleSearch = (e) => {
        setSearchString(e.target.value || "")
    }

    return (
        <div>
            {store.bid.id && <BidHeader />}

            <div style={{position: "relative", width: "100%", height: rowsSearched.length * ROW_CELL_HEIGHT + 100}}>
                <div ref={gridAndSettingRef} style={{position: "sticky", top: 0}}>
                    <Grid container justifyContent={"space-between"} alignItems={"center"} height={"60px"}>
                        <Grid item>
                            <Grid container alignItems={"center"} spacing={2}>
                                <Grid item paddingRight={4}>
                                    <h4>Line Items</h4>
                                </Grid>
                                <Grid item>
                                    <Button
                                        endIcon={expandAll ? <UnfoldLessRoundedIcon/> : <UnfoldMoreRoundedIcon />}
                                        onClick={handleToggleExpandAll}
                                        color="secondary" size="small"
                                        variant="contained"
                                    >
                                        {`${!expandAll ? "Expand" : "Collapse"} all`}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={handleCleanBid} endIcon={<CancelPresentationRoundedIcon/>} color="secondary" size="small" variant="contained">
                                        Clean up empty lines
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={()=>{setColumnMenuOpen(true)}} ref={columnMenuAnchor} endIcon={<ViewWeekOutlinedIcon/>} color="secondary" size="small" variant="contained">Columns</Button>
                                    <ColumnsMenu
                                        isOpen={columnMenuOpen}
                                        close={()=>{setColumnMenuOpen(false)}}
                                        anchorEl={columnMenuAnchor.current}
                                        onToggleSticky={handleColumnToggleSticky}
                                        onToggleVisibility={handleColumnToggleVisibility}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button onClick={handleExport} endIcon={<DownloadOutlinedIcon />} color="secondary" size="small" variant="contained">Export</Button>
                                </Grid>
                                <Grid item>
                                    <TopSheetButton
                                        variant="endIcon"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} alignItems={"center"}>
                                <Grid item>
                                    <HighlightsOverview registerHighlightsDataFunction={registerChildHighlightsFunction} />
                                </Grid>
                                <Grid item>
                                    <SearchBox handleSearch={handleSearch}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <ReactGridContainer
                        id={`react-grid-${themeMode}`} ref={reactGridRef}
                        style={{
                            width: "100%", maxHeight: "calc(100vh - 60px)", // position: "sticky",
                            overflowX: "auto", overflowY: "hidden", marginBottom: 0, top: 0
                        }}
                    >
                        <ReactGrid
                            rows={rowsSearched}
                            columns={columns}
                            onCellsChanged={handleCellChanged}
                            onRowsReordered={handleRowsReorder}
                            canReorderRows={handleCanReorderRows}
                            onSelectionChanged={handleSelectionChanged}
                            enableRowSelection
                            stickyLeftColumns={2}
                            stickyRightColumns={columnRightStickyCount}
                            horizontalStickyBreakpoint={100}
                            stickyTopRows={1}
                            enableRangeSelection
                            customCellTemplates={{
                                simpleFormula: new FormulaCellTemplate(highlights),
                                simpleFormulaOneAsEmpty: new FormulaCellTemplate(highlights, true),
                                customChevron: new CustomChevronCellTemplate(highlights, handleExpandRow, handleOpenLineGroupMenu, handleCopyRow, handleDeleteRow, handleAddonPopupOpen),
                                customDropdownRateType: new CustomDropdownCellTemplate(highlights, rateTypes),
                                customDropdownCurrency: new CustomDropdownCellTemplate(highlights, currencies),
                                customDropdownResourceType: new CustomDropdownCellTemplate(highlights, resourceTypes)
                            }}
                        />
                    </ReactGridContainer>
                </div>
            </div>

            {(canViewConfidentialData && store.bid.id) &&
            <div>
                <AddonsForm
                    addons={rootAddons}
                />
                <BidNotes
                    bidId={BID_ID}
                    readOnly={readOnly}
                />
                <Divider sx={{marginTop: 6, marginBottom: 2}}/>
                {!isTemplate &&
                <BidBills
                    bidId={BID_ID}
                    quoted={quotedTotal}
                    legacy={store.bid.project.legacy_bills}
                />}
                {!isTemplate && <Grid container spacing={8} sx={{marginBottom: 6}}>
                    {canViewConfidentialData ? (
                        <Grid item md={8}>
                            <Comments comments={store.bid.comments} modelType={"bid"} modelInstanceId={BID_ID} />
                        </Grid>
                    ) : null}
                    <Grid item md={4}>
                        {store.bid.activity.map(activity => (
                            <BidActivity key={activity.id} {...activity} />
                        ))}
                    </Grid>
                </Grid>}
            </div>}
            <LineGroupMenu
                anchorEl={lineGroupMenuAnchor}
                lineGroupData={lineGroupPopupData}
                close={()=>{setLineGroupMenuAnchor(null)}}
            />
            {store.bid.id &&
                <BidSum
                    registerSumDataFunction={registerChildSumDataFunction}
                    isTemplate={false}
                    onSectionLinkClick={handleSectionLinkClick}
                />
            }
        </div>
    )
}