import { Button } from "@mui/material"
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { useState } from "react";
import { TopSheetConfigurator } from "./TopSheetConfigurator";
import PropTypes from "prop-types"

const TopSheetButton = ({variant, onOpen}) => {
    const [topConfigOpen, setTopConfigOpen] = useState(false)
    const [requestFormOpen, setRequestFormOpen] = useState(false)
    const handleOpenTopSheetConfig = () => {
        setTopConfigOpen(true)
        onOpen && onOpen()
    }
    return (
        <>
            <Button
                sx={{color: "black", fontSize: variant == "startIcon" && "10px"}}
                size={variant == "endIcon" ? "small" : "medium"}
                variant="contained"
                startIcon={variant == "startIcon" && <TuneRoundedIcon />}
                endIcon={variant == "endIcon" && <TuneRoundedIcon />}
                onClick={handleOpenTopSheetConfig}
                color="secondary"
            >
                Top Sheet
            </Button>
            <TopSheetConfigurator 
                open={topConfigOpen} 
                close={()=>{setTopConfigOpen(false)}}
                requestFormOpen={requestFormOpen}
                setRequestFormOpen={setRequestFormOpen}
            />
        </>
    )
}

// attempt to have IDE autocomplete string options
TopSheetButton.propTypes = {
    variant: PropTypes.oneOf(["startIcon", "endIcon"]).isRequired
}

export default TopSheetButton