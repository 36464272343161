import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { useState } from "react";
import { AddonPopup } from "./AddonForm";
import LineGroupPopup from "@/sheet/LineGroupPopup";

export default function LineGroupMenu ( { anchorEl, close, lineGroupData } ) {
    const [ addonPopupOpen, setAddonPopupOpen ] = useState(false)
    const [ lineGroupPopupOpen, setLineGroupPopupOpen ] = useState(false)

    const handleEdit = () => {
        setLineGroupPopupOpen(true)
        close()
    }
    const handleDeleteLine = () => {
        // implement confirmation dialog
    }
    const handleOpenCreateAddonPopup = () => {
        setAddonPopupOpen(true)
        close()
    }
    const copyGroup = () => {
        // implement confirmation dialog
    }
    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={close}
            >
                <MenuItem onClick={handleEdit}>
                    <ListItemIcon><EditOutlinedIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>Batch Edit</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDeleteLine}>
                    <ListItemIcon><DeleteOutlinedIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleOpenCreateAddonPopup}>
                    <ListItemIcon><LocalOfferOutlinedIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>Create Add-on</ListItemText>
                </MenuItem>
                <MenuItem onClick={copyGroup}>
                    <ListItemIcon><ContentCopyOutlinedIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>Duplicate</ListItemText>
                </MenuItem>
            </Menu>

            <AddonPopup 
                isOpen={addonPopupOpen}
                lineGroupId={lineGroupData?.id}
                close={()=>{setAddonPopupOpen(false)}}
            />

            {lineGroupData &&
            <LineGroupPopup 
                isOpen={lineGroupPopupOpen}
                lineGroup={lineGroupData}
                close={()=>{setLineGroupPopupOpen(false)}}
                popupType={"override"}
            />}
        </>
    )
}