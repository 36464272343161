import { selectAccountId } from "@/auth/authSlice";
import * as React from "react";
import { useSelector } from "react-redux";

export default function HighlightCell({highlights, hover}) {
    const accountId = useSelector(selectAccountId)
    const otherHighlights = highlights.filter(highlight=>highlight.accountId!=accountId)
    const highlightColor = highlights[0].borderColor
    if (otherHighlights.length == 0) {
        return
    }
    return (
        <div 
            style={{
                position: "absolute", width: "calc(100% - 3px)", 
                height: "calc(100% - 3px)", top: 0, left: 0, 
                border: `${highlightColor} 2px solid`,
                pointerEvents: "none"
            }} 
        >
            {hover &&
            <div style={{position: "absolute", right: -2, top: -15, background: highlightColor, fontSize: 9, padding: 2, paddingLeft: 4, paddingRight: 4, borderRadius: 2}}>
                <span>{otherHighlights.map(h=>h.accountName).join(", ")}</span>
            </div>}
        </div>
    )
}