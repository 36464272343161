import { useState } from "react"
import { useTheme } from "@mui/material"
import Modal from "@mui/material/Modal"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import ErrorIcon from '@mui/icons-material/Error';
import { CustomBox, Subtitle, TargetsWarning } from "@/common/StyledComponents"
import CircularProgress from '@mui/material/CircularProgress';

export default function LineGroupWarningPopup({isOpen, onClose, onConfirm}){
    const [loading, setLoading] = useState(false);
    const theme = useTheme()
    const handleConfirm = () => {
        setLoading(true)
        onConfirm()
        onClose()
        setLoading(false)
    }
    return (
        <Modal open={isOpen} onClose={onClose}>
            <CustomBox sx={{ background: theme.palette.background.default }}>
                <TargetsWarning>
                    <Grid container spacing={1}>
                        <Grid item><ErrorIcon color="warning"/></Grid>
                        <Grid item>
                            <Subtitle>Attention</Subtitle>
                            <p>These changes will be applied to all existing line items in this group. <br/> Please ensure that your modifications are intended to overwrite existing values before proceeding</p>
                        </Grid>
                        <Grid container justifyContent={"right"} spacing={3}>
                            <Grid item md={3}>
                                <Button fullWidth variant="outlined" onClick={onClose}>Cancel</Button>
                            </Grid>
                            <Grid item md={3}>
                                {loading ? <CircularProgress /> : <Button fullWidth variant="contained" onClick={handleConfirm}>Yes! Override</Button>}
                            </Grid>
                        </Grid>                 
                    </Grid>
                </TargetsWarning>
            </CustomBox>
        </Modal>
    )
}