import * as React from "react";
import {
    isNavigationKey,
    getCellProperty,
    isAlphaNumericKey,
    keyCodes,
} from "@silevis/reactgrid";
import { tryParseFormula } from "./formulaParser";
import { formattedCurrency } from "@/utils";
import HighlightCell from "./HighlightCell";
import { Grid } from "@mui/material";
import { parse } from "date-fns";

export class FormulaCellTemplate {
    constructor (highlights, oneAsEmpty = false) {
        this.highlights = highlights
        this.oneAsEmpty = oneAsEmpty
    }
    
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text
        const value = parseFloat(getCellProperty(uncertainCell, "value", "number"))

        return { ...uncertainCell, text, value }
    }

    getStyle() {
        return {
            overflow: "visible"
        }
    }

    handleKeyDown(
        cell,
        keyCode,
        ctrl,
        shift,
        alt
    ){
        if (!ctrl && !alt && isAlphaNumericKey(keyCode)) {
            const char = String.fromCharCode(keyCode)
            if (/^\d$/.test(char)) {
                const updatedCell = this.update(cell, { ...cell, text: parseFloat(char), value: parseFloat(char) })
                return { cell: updatedCell, enableEditMode: true }
            }
        }
        return {
            cell,
            enableEditMode:keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
        }
    }

    update(
        cell,
        cellToMerge
    ){
        const parsedVal = parseValue(cellToMerge.value, this.oneAsEmpty)
        return this.getCompatibleCell({ ...cell, text: parsedVal.toString(), value: parsedVal });
    }

    render(
        cell,
        isInEditMode,
        onCellChanged = (cell, commit) => {}
    ){
        const cellHighlights = this.highlights.filter(h=>(h.rowId == cell.rowId) && (h.columnId == cell.columnId))
        return (
            <FormulaCell 
                isInEditMode={isInEditMode}
                highlights={cellHighlights}
                cell={cell}
                getCompatibleCell={this.getCompatibleCell}
                onCellChanged={onCellChanged}
                oneAsEmpty={this.oneAsEmpty}
            />
        )
    }
}

const FormulaCell = ({cell, getCompatibleCell, onCellChanged, highlights, isInEditMode, oneAsEmpty}) => {
    const [ hover, setHover ] = React.useState() 
    const handleMouseEnter = () => {
        setHover(true)
    }
    const handleMouseLeave = () => {
        setHover(false)
    }
    return (
        <div style={{width: "100%", height: "100%"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div style={{height: "100%", justifyContent: "right", alignItems: "center", display: "flex"}}>
                {
                isInEditMode ?
                <input
                    style={{width: "100%", border: "none", outline: "none"}}
                    ref={(input) => {
                    input && input.focus();
                }}
                    defaultValue={cell.value}
                    onChange={(e) => {
                        const parsedVal = parseValue(e.currentTarget.value, oneAsEmpty)
                        onCellChanged(
                            getCompatibleCell({ ...cell, text: parsedVal.toString(), value: parseFloat(parsedVal) }),
                            false
                        )
                    }}
                    onCopy={(e) => e.stopPropagation()}
                    onCut={(e) => e.stopPropagation()}
                    onPaste={(e) => e.stopPropagation()}
                    onPointerDown={(e) => e.stopPropagation()}
                    onKeyDown={(e) => {
                        if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
                            e.stopPropagation();
                        }
                    }
                    onBlur={(e) =>
                        {
                            const parsedVal = parseValue(e.currentTarget.value, oneAsEmpty)
                            onCellChanged(
                                getCompatibleCell({ ...cell, text: parsedVal.toString(), value: parseFloat(parsedVal) }),
                                true
                            )
                        }
                    }
                />
                :
                <div>
                    <span>
                        {displayValue(cell.value, oneAsEmpty)}
                    </span>
                </div>
                }
            </div>
            {(highlights.length > 0) && <HighlightCell highlights={highlights} hover={hover}/>}
        </div>
    )
}

const displayValue = (value, oneAsEmpty) => {
    if (oneAsEmpty) {
        if (value == 1) {
            return <span style={{color: "#66666666"}}>1</span>
        }
    } else {
        if (value == 0) {
            return <span style={{color: "#66666666"}}>0</span>
        }
    }
    return formattedCurrency(value)
}

const parseValue = (value, oneAsEmpty) => {
    const assumeZero = (value == "" || isNaN(value))
    const inputIsZero = value == 0
    const val = assumeZero ? 0 : value
    const parsedVal = (oneAsEmpty && assumeZero && !inputIsZero) ? 1 : tryParseFormula(val)
    return parsedVal
}