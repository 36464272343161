import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {useTheme, useMediaQuery, Button, Modal, Grid, Divider, Link, Snackbar, Alert, CircularProgress, ToggleButton, ToggleButtonGroup} from "@mui/material"
import SaveAltIcon from '@mui/icons-material/SaveAlt'

import { request } from "@/Api";
import { TopSheetPreview } from "@/sheet/TopSheetPreview";
import { AllGroupAccordions, SwitchItem, SignerPopup, InfoInput, SendersInput } from "@/sheet/TopSheetConfiguratorComponents";
import TopSheetConfiguratorLegalEntity from "@/sheet/TopSheetConfiguratorLegalEntity";
import { downloadTopsheet } from "@/sheet/TopsheetDownload";
import { BID_STATUS_APPROVED } from "@/sheet/constants";
import { selectHasPermission } from "@/auth/authSlice";
import { PreviewContainer } from "@/sheet/TopSheetPreviewComponents"
import { store, storeBid } from "@/sheet/bidStore";

export function TopSheetConfigurator({ requestFormOpen, setRequestFormOpen, open, close }){
    const bidBeforeConfig = useRef()
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down("md"))
    
    const [isResetting, setIsResetting] = useState(false)
    const [signatureRequested, setSignatureRequested] = useState(false)
    
    const bid = store.bid
    const project = store.bid.project
    const bidLiabilityWaiver = useSelector(state => selectHasPermission(state, "bid_liability_waiver"))
    const bidCodeOfConduct = useSelector(state => selectHasPermission(state, "bid_code_of_conduct"))
    const bidCovid19 = useSelector(state => selectHasPermission(state, "bid_covid_19"))

    const closeRequestForm = () => {
        setRequestFormOpen(false)
    }

    const onInfoBlur = (field) => (event) => {
        if (bid.top_sheet_extra_info == event.target.value) {
            return
        }
        updateTopSheet({...bid, [field]: event.target.value})
    }

    const onSendersChange = (event, value) => {
        const top_sheet_sender_ids = value.map(sender=>sender.id)
        updateTopSheet({
            ...bid,
            top_sheet_senders: value,
            top_sheet_sender_ids: top_sheet_sender_ids
        })
    }

    const onSwitchChange = (field) => (_, value) => {
        updateTopSheet({...bid, [field]: value})
    }

    const onToggleButtonChange = (field) => (_, value) => {
        if (value == null){
            return
        }
        updateTopSheet({...bid, [field]: value})
    }

    const resetTopSheet = () => {
        setIsResetting(true)
        updateTopSheet(bidBeforeConfig.current)
    }

    const updateTopSheet = (bidData) => {
        storeBid(bidData)
        console.log(store.bid)
        request.patch(`/bids/${bidData.id}/topsheet`, bidData)
        .then(()=>{
            setIsResetting(false)
        })
    }

    useEffect(()=>{
        if (!bid.id){
            return
        }
        bidBeforeConfig.current = {...bid}
        bidBeforeConfig.current.top_sheet_sender_ids = bid.top_sheet_senders.map((sender)=>{return sender.id})
    }, [open])

    if (!bid.id){
        return
    }

    return (
        <Modal open={open} onClose={close}>
            <div style={{display: "flex", height: "100%"}}>
                <SignerPopup 
                    bidId={bid.id}
                    open={requestFormOpen}
                    close={closeRequestForm}
                    setSignatureRequested={setSignatureRequested}
                />
                <Snackbar
                    open={signatureRequested}
                    autoHideDuration={5000}
                    onClose={(event, reason) => {setSignatureRequested(false)}}
                >
                    <Alert severity="success">Signature request successful!</Alert>
                </Snackbar>

                {isSmall ? null : project ? (
                    <TopSheetPreview />
                ) : (
                    <PreviewContainer>
                        <h1>Unavailable</h1>
                    </PreviewContainer>
                )}

                <div style={{
                    background: theme.palette.background.default,
                    width: isSmall ? "100%" : "600px",
                    padding: "25px 40px 0px 40px",
                    overflowY: "scroll",
                }}>
                    {project && <>
                        <p>{project.name}</p>
                        <p style={{fontSize: 20, fontWeight: 600}}>Top Sheet Configurator</p>
                        <h4>Producers</h4>
                        <SendersInput
                            onChange={onSendersChange}
                            projectId={project.id}
                            value={bid.top_sheet_senders}
                        />
                        <Divider sx={{marginBottom: 4}}/>
                        <h4>Company Info</h4>
                        <TopSheetConfiguratorLegalEntity legalEntity={project.legal_entity}/>
                        <Divider sx={{marginBottom: 4, marginTop: 4}}/>
                    </>}

                    <h4>Extra Header Info</h4>
                    <InfoInput 
                        onBlur={onInfoBlur("top_sheet_extra_info")}
                        text={bid.top_sheet_extra_info}
                    />
                    <Divider sx={{marginTop: 4, marginBottom: 4}}/>

                    <h4>Section & Category Settings</h4>
                    <AllGroupAccordions updateTopSheet={updateTopSheet} bid={bid} />
                    <Divider sx={{marginTop: 4, marginBottom: 4}}/>

                    <h4>General Settings</h4>
                    {bidLiabilityWaiver && <SwitchItem label="Liability Waiver" checked={bid.include_liability_waiver} onChange={onSwitchChange("include_liability_waiver")} />}
                    {bidCodeOfConduct && <SwitchItem label="Code of Conduct" checked={bid.include_code_of_conduct} onChange={onSwitchChange("include_code_of_conduct")} />}
                    {bidCovid19 && <SwitchItem label="COVID-19 Costs" checked={bid.include_covid_terms} onChange={onSwitchChange("include_covid_terms")} />}
                    <SwitchItem label="Billing Schedule" checked={bid.include_bill_schedule} onChange={onSwitchChange("include_bill_schedule")} />
                    <SwitchItem label="Financial Terms" checked={bid.include_financial_terms} onChange={onSwitchChange("include_financial_terms")} />
                    <SwitchItem label="Notes Section" checked={bid.include_notes} onChange={onSwitchChange("include_notes")} />
                    <SwitchItem label="Decimals" checked={bid.include_decimals} onChange={onSwitchChange("include_decimals")} />
                    <SwitchItem label="Zero Lines" checked={bid.include_zero_lines} onChange={onSwitchChange("include_zero_lines")} />
                    <SwitchItem label="Condensed Mode" checked={bid.top_sheet_condensed} onChange={onSwitchChange("top_sheet_condensed")} />

                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item>{"Signatures Required"}</Grid>
                        <Grid item>
                            <ToggleButtonGroup color="primary" size="small" sx={{height: 24}} value={bid.signer_count} exclusive onChange={onToggleButtonChange("signer_count")}>
                                <ToggleButton value={0} key={0}>0</ToggleButton>
                                <ToggleButton value={1} key={1}>1</ToggleButton>
                                <ToggleButton value={2} key={2}>2</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>

                    <Divider sx={{marginTop: 4, marginBottom: 4}}/>
                    
                    <div style={{
                        background: theme.palette.background.default,
                        textAlign: "center",
                        position: "sticky",
                        bottom: "0",
                        padding: "20px",
                        alignItems: "center",
                        margin: "auto",
                    }}>
                        <Grid container spacing={1}>
                            <Grid md={12} item>
                                <Button fullWidth variant="contained" onClick={close}>
                                    Save & Close
                                </Button>
                            </Grid>
                            <Grid md={12} item>
                                {isResetting ? 
                                    <CircularProgress /> :
                                    <Button sx={{color: "black"}} fullWidth variant="contained" onClick={resetTopSheet} color="inherit">
                                        Reset Changes
                                    </Button>
                                }
                            </Grid>
                            {bid.status == BID_STATUS_APPROVED ?
                                <Grid md={12} item>
                                    <Button sx={{color: "white"}} fullWidth variant="contained" onClick={()=>{setRequestFormOpen(true)}}>
                                        Request Client Signature
                                    </Button>
                                </Grid> : null
                            }
                            <Grid md={12} item>
                                <Link onClick={()=>{downloadTopsheet(bid.name)}} sx={{textDecoration: "none", cursor: "pointer"}}>
                                    <Grid container alignItems={"center"} justifyContent={"center"}>
                                        <SaveAltIcon />
                                        &nbsp;&nbsp;
                                        <span>Download PDF</span>
                                    </Grid>
                                </Link>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </Modal>
    )
}