import * as React from "react";
import {
  isNavigationKey,
  getCellProperty,
  isAlphaNumericKey,
  keyCodes
} from "@silevis/reactgrid";
import "./customCellStyle.css"
import { Grid } from "@mui/material";
import { useState } from "react";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { useRef } from "react";
import { useEffect } from "react";
import HighlightCell from "./HighlightCell";
import ConfirmationDialog from "@/common/ConfirmationDialog";
import { LINE_GROUP_ROW_ID_PREFIX } from "./constants";
import { getLineGroupsHash } from "./bidStore";

export default class CustomChevronCellTemplate {
    constructor (highlights, expandGroup, openLineGroupMenu, openCopyConfirmation, openDeleteConfirmation, openAddonForm) {
        this.highlights = highlights
        this.openLineGroupMenu = openLineGroupMenu
        this.expandGroup = expandGroup
        this.openCopyConfirmation = openCopyConfirmation
        this.openDeleteConfirmation = openDeleteConfirmation
        this.openAddonForm = openAddonForm
    }

    getStyle() {
        return {
            overflow: "visible"
        }
    }
    
    getCompatibleCell(uncertainCell) {
        const text = getCellProperty(uncertainCell, "text", "string");
        const value = parseFloat(text);
        return { ...uncertainCell, text, value };
    }

    handleKeyDown(
        cell,
        keyCode,
        ctrl,
        shift,
        alt
    ){
        if (!ctrl && !alt && isAlphaNumericKey(keyCode)){
            const char = String.fromCharCode(keyCode)
            const updatedCell = this.update(cell, { ...cell, text: char, value: parseFloat(char) })
            return { cell: updatedCell, enableEditMode: true }
        }
        return {
            cell,
            enableEditMode: keyCode == keyCodes.POINTER || keyCode == keyCodes.ENTER
        }
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell, isInEditMode, onCellChanged = (cell, commit) => {}) {
        const cellHighlights = this.highlights.filter(h=>(h.rowId == cell.rowId) && (h.columnId == cell.columnId))
        return (
            <CustomCheveronCell
                cell={cell}
                isInEditMode={isInEditMode}
                expandGroup={this.expandGroup}
                openLineGroupMenu={this.openLineGroupMenu}
                onCellChanged={onCellChanged}
                getCompatibleCell={this.getCompatibleCell}
                copyRow={this.openCopyConfirmation}
                deleteRow={this.openDeleteConfirmation}
                openAddonForm={this.openAddonForm}
                highlights={cellHighlights}
            />
        )
    }
}

const CustomCheveronCell = ({cell, isInEditMode, expandGroup, openLineGroupMenu, onCellChanged, getCompatibleCell, copyRow, deleteRow, openAddonForm, highlights}) => {
    const [ hover, setHover ] = useState() 
    const handleMouseEnter = () => {
        setHover(true)
    }
    const handleMouseLeave = () => {
        setHover(false)
    }
    return (
        <div style={{width: "100%"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {isInEditMode ? 
                <CustomChevronCellEditMode 
                    cell={cell} 
                    onCellChanged={onCellChanged}
                    getCompatibleCell={getCompatibleCell}
                /> : 
                <CustomChevronCellReadMode 
                    cell={cell} 
                    expandGroup={expandGroup}
                    openLineGroupMenu={openLineGroupMenu}
                    copyRow={copyRow}
                    deleteRow={deleteRow}
                    openAddonForm={openAddonForm}
                />
            }
            {(highlights.length > 0) && <HighlightCell highlights={highlights} hover={hover} />}
        </div>
    )
}

const CustomChevronCellReadMode = ({cell, expandGroup, openLineGroupMenu, copyRow, deleteRow, openAddonForm}) => {
    const [ pointerOver, setPointerOver ] = useState(false)
    const [ deletePopupOpen, setDeletePopupOpen ] = useState(false)

    const menuRef = useRef() 
    const [prefix, id] = cell.rowId.split("-")
    const lineGroup = prefix == LINE_GROUP_ROW_ID_PREFIX ? getLineGroupsHash()[id] : null

    const handleExpand = () => {
        const nowExpanded = !cell.isExpanded
        expandGroup(cell.rowId, nowExpanded)
    }

    const handleOpenMenu = (e) => {
        e.stopPropagation()
        e.preventDefault()
        openLineGroupMenu(cell.rowId, menuRef)
    }

    const handleCopy = (e) => {
        copyRow(cell.rowId)
    }

    const handleDeletePopupOpen = () => {
        setDeletePopupOpen(true)
    }

    const handleDelete = (e) => {
        deleteRow(cell.rowId)
    }
    
    const handleOpenAddonPopup = (e) => {
        const addonId = cell.rowId.split("-")[1]
        openAddonForm(addonId)
    }

    return (
        <div
            style={{width: "100%"}}
            onPointerEnter={()=>{setPointerOver(true)}}
            onPointerLeave={()=>{setPointerOver(false)}}
        >
            <Grid container justifyContent={"space-between"} wrap="nowrap">
                <Grid item>
                    <Grid container alignItems={"center"} wrap="nowrap">
                        <Grid item width={32} marginLeft={cell.indent * 3}>
                        {pointerOver && 
                            <Grid container wrap="nowrap" height={14}>
                                <Grid item>
                                    <DeleteOutlineRoundedIcon
                                        fontSize="tiny"
                                        onClick={lineGroup ? handleDeletePopupOpen : handleDelete}
                                        style={{cursor: "pointer", color: "grey"}}
                                    />
                                </Grid>
                                <Grid item paddingRight={1}>
                                    <ContentCopyRoundedIcon
                                        fontSize="tiny"
                                        onClick={handleCopy}
                                        style={{cursor: "pointer", color: "grey"}}
                                    />
                                </Grid>
                            </Grid>
                        }
                        </Grid>
                        <Grid item maxWidth={165} overflow={"auto"}>
                            <span style={{paddingRight: 10}}>{cell.text}</span>
                        </Grid>
                        {cell.hasChildren && 
                            <Grid item ref={menuRef} height={"12px"}>
                                <MoreVertIcon
                                    fontSize="tiny"
                                    onClick={handleOpenMenu}
                                    style={{cursor: "pointer"}}
                                />
                            </Grid>
                            }
                        {cell.rowId.startsWith("addon") &&
                        <Grid item>
                            <LocalOfferOutlinedIcon 
                                onClick={handleOpenAddonPopup}
                                style={{cursor: "pointer"}}  
                                fontSize="tiny"
                            />
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item>
                    {cell.hasChildren && 
                    <ArrowForwardIosRoundedIcon 
                        fontSize="tiny" 
                        onClick={handleExpand}
                        style={{height: 10, cursor: "pointer", transform: cell.isExpanded && "rotate(90deg)"}}
                    />}
                </Grid>
            </Grid>

            {lineGroup && 
            <ConfirmationDialog
                open={deletePopupOpen}
                closeDialog={() => setDeletePopupOpen(false)}
                title={`Delete "${lineGroup.name}"?`}
                callback={handleDelete}
            >
                <p>{"Deleting a group will remove all line items contained inside. This is non-reversable."}</p>
            </ConfirmationDialog>}
        </div>
    )
}

const CustomChevronCellEditMode = ({cell, onCellChanged, getCompatibleCell}) => {
    return (
        <input
            ref={(input) => {
                input && input.focus();
            }}
            defaultValue={cell.text}
            onChange={(e) =>
                onCellChanged(
                    getCompatibleCell({ ...cell, text: e.currentTarget.value }),
                    false
                )
            }
            onCopy={(e) => e.stopPropagation()}
            onCut={(e) => e.stopPropagation()}
            onPaste={(e) => e.stopPropagation()}
            onPointerDown={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
            if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
                e.stopPropagation();
            }}
            onPointerEnter={(e) => e.stopPropagation()}
            onBlur={(e) => 
                {
                    onCellChanged(
                        getCompatibleCell({ ...cell, text: e.currentTarget.value, value: parseFloat(e.currentTarget.value) }),
                        true
                    )
                }
            }
            style={{
                outline: "none",
                border: "none"
            }}
        />
    )
}