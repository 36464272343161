import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components'

import { Link, useTheme } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconButton from "@mui/material/IconButton";
import Chip from '@mui/material/Chip';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SellIcon from '@mui/icons-material/Sell';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ElderlyIcon from '@mui/icons-material/Elderly';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

import InlineRename from "@/sheet/InlineRename";
import BidTemplatePopup from "@/bids/BidTemplatePopup";
import BidCopyPopup from '@/bids/BidCopyPopup';
import { downloadFile, getCurrencySymbol } from "@/utils";
import { request } from "@/Api";
import StrategyComponents from '@/common/StrategyComponents';
import { InternalLink } from '@/common/CommonComponents';
import { ACCOUNTING_STRATEGY } from '@/constants';
import { BID_STATUS_SIGNED, BID_TYPE_CO } from '@/sheet/constants';
import { getClientCurrency, store } from '@/sheet/bidStore';

const Container = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
`
const TitleSpan = styled.span`
    font-size: 22px;
    white-space: nowrap;
`

export default function BidTitle({ bidId, isLegacy }) {
    const theme = useTheme()

    const [ menuAnchorEl, setMenuAnchorEl] = useState(null)
    const [ showBidRename, setShowBidRename ] = useState(false)
    const [ bidTemplatePopupOpen, setBidTemplatePopupOpen ] = useState(false)
    const [ bidToCopy, setBidToCopy ] = useState(null)

    const isSigned = store.bid.status == BID_STATUS_SIGNED
    const bid = store.bid
    const projectCurrency = getClientCurrency()
    const bidType = store.bid.bid_type

    const handleMenuOpen = (event) => {
        event.stopPropagation()
        setMenuAnchorEl(event.currentTarget)
    }

    const handleMenuClose = (event) => {
        event.stopPropagation()
        setMenuAnchorEl(null)
    }

    const handleBidRename = (event) => {
        handleMenuClose(event)
        setShowBidRename(true)
    }

    const renameBid = (value) => {
        request.patch(`/bids/${bidId}`, {name: value})
        .then(()=>{
            setShowBidRename(false)
        })
    }
    
    const closeRename = (event) => {
        event.stopPropagation()
        setShowBidRename(false)
    }

    const handleCsvExport = (event) => {
        handleMenuClose(event)
        downloadFile(`bids/${bidId}/csv-export`)
    }

    const handleCopy = (event) => {
        handleMenuClose(event)
        setBidToCopy(bid)
    }

    const handleTemplate = (event) => {
        handleMenuClose(event)
        setBidTemplatePopupOpen(true)
    }

    const handleRevertCleanBid = () => {
        handleMenuClose(event)
        request.put(`/bids/${bidId}/revert-clean`)
    }

    return showBidRename ? (
        <InlineRename
            defaultName={bid.name}
            close={closeRename}
            rename={renameBid}
        />
    ) : (
        <div>
            {bid.project ?
            <div style={{paddingBottom: 10}}>
                <Link sx={{textDecoration: "none", fontSize: 14}} href={`/projects/${bid.project.id}`}>{bid.project.name}</Link>
            </div> : 
            <div>
                <span style={{fontSize: 14, color: "gray"}}>Template</span>
            </div>
            }
            <Container>
                <TitleSpan>{bid.name}</TitleSpan>
                <IconButton sx={{margin: "0 6px"}} onClick={handleMenuOpen}>
                    <MoreVertIcon fontSize="small" />
                </IconButton>
                <Chip
                    label={bidType == BID_TYPE_CO ? bidType : `${bidType} Bid Sheet`}
                    color="warning"
                    size="small"
                    variant="outlined"
                    sx={{fontSize: "10px", backgroundColor: theme.palette.warning.light}}
                />
                <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleBidRename}>
                        <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
                        <ListItemText>Rename</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleCsvExport}>
                        <ListItemIcon><FileDownloadIcon fontSize="small" /></ListItemIcon>
                        <ListItemText>Export as CSV</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleCopy}>
                        <ListItemIcon><ContentCopyIcon fontSize="small"/></ListItemIcon>
                        <ListItemText>Duplicate {bid.is_change_order ? 'Change Order' : 'Bid Sheet'}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleTemplate}>
                        <ListItemIcon><SellIcon fontSize="small"/></ListItemIcon>
                        <ListItemText>Save as Template</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleRevertCleanBid}>
                        <ListItemIcon><SettingsBackupRestoreIcon fontSize="small"/></ListItemIcon>
                        <ListItemText>{`Unarchive ${getCurrencySymbol(projectCurrency)}0 lines`}</ListItemText>
                    </MenuItem>
                    {isLegacy ? (
                        <InternalLink href={`/bids/${bid.id}`} sx={{textDecoration: "none", color: "inherit"}}>
                            <MenuItem>
                                <ListItemIcon><NewReleasesIcon fontSize="small" /></ListItemIcon>
                                <ListItemText>Use New Bid</ListItemText>
                            </MenuItem>
                        </InternalLink>
                    ) : (
                        <InternalLink href={`/bids-legacy/${bid.id}`} sx={{textDecoration: "none", color: "inherit"}}>
                            <MenuItem>
                                <ListItemIcon><ElderlyIcon fontSize="small" /></ListItemIcon>
                                <ListItemText>Use Legacy Bid</ListItemText>
                            </MenuItem>
                        </InternalLink>
                    )}
                    
                    <StrategyComponents strategy={ACCOUNTING_STRATEGY}>
                        <InternalLink href={`/projects/${bid.project_id}/actuals`} sx={{textDecoration: "none", color: "inherit"}}>
                            {isSigned ?
                            <MenuItem>
                                <ListItemIcon><FactCheckIcon fontSize="small"/></ListItemIcon>
                                <ListItemText>Actualize</ListItemText>
                            </MenuItem> : null}
                        </InternalLink>
                    </StrategyComponents>
                </Menu>
                <BidTemplatePopup
                    bidId={bidId}
                    bidTemplatePopupOpen={bidTemplatePopupOpen}
                    setBidTemplatePopupOpen={setBidTemplatePopupOpen}
                />
                <BidCopyPopup
                    bid={bidToCopy}
                    setBid={setBidToCopy}
                />
            </Container>
        </div>
    )
}
